//import { createApp } from 'vue'
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import NavBar from "@/components/core/NavBar";
import AdminNavBar from "@/components/core/AdminNavBar";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import Footer from "@/components/core/Footer.vue";
import VueSimpleAlert from "vue-simple-alert";
import "./axios";
import VueConfirmDialog from "vue-confirm-dialog";
import Vuelidate from "vuelidate";
import VueCollapse from "vue2-collapse";

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.loggedIn) {
      next({ name: "AdminLogin" });
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.requiresVisitor)) {
    if (store.getters.loggedIn) {
      next({ name: "Permission" });
    } else {
      next();
    }
  } else {
    next();
  }
});

Vue.use(VueSweetalert2);
Vue.use(VueConfirmDialog);
Vue.use(Vuelidate);
Vue.use(VueCollapse);
Vue.use(VueSimpleAlert);

Vue.component("nav-bar", NavBar);
Vue.component("footer-bar", Footer);
Vue.component("admin-nav-bar", AdminNavBar);
Vue.component("vue-confirm-dialog", VueConfirmDialog.default);

Vue.config.productionTip = false;

//const app = createApp(App).use(router).use(store)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

//app.mount('#app')
