<template>
  <div>
    <div id="page-container">
      <!-------------Main container start--------------->
      <div id="content-wrap">
        <!-- all other page content start -->
        <admin-nav-bar></admin-nav-bar>

        <div
          class="container-fluid"
          id="headerbx"
          style="background-image: url(asset/images/tp_bg.png)"
        >
          <div class="container-fluid filtersearch">
            <div class="row">
              <div class="col-md-12">
                <div class="headingbg">
                  <h5>
                    <img
                      src="asset/images/summary.png"
                      class="img-responsive heading_image"
                      align="left"
                    />Add Client
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid" id="contentmaindiv">
          <div class="whitepatch dualbox">
            <div class="fr_b">
              <div class="row">
                <div class="col-md-2 col-xs-6">
                  <div class="form-group">
                    <label for="text" class="required-field">Client Name</label>
                    <input
                      type="text"
                      class="form-control"
                      id="client_name"
                      name="client_name"
                      :class="{
                        'is-invalid': isSubmitted && $v.client_name.$error
                      }"
                      v-model="client_name"
                    />
                    <div
                      v-if="isSubmitted && $v.client_name.$error"
                      class="invalid-feedback"
                      style="color: red"
                    >
                      <span v-if="!$v.client_name.required">Client Name is required</span>
                      <span v-if="!$v.client_name.maxLength"
                        >Max length of 100 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-xs-6">
                  <div class="form-group">
                    <label for="email" class="required-field">Email Address</label>
                    <input
                      type="email"
                      class="form-control"
                      id="email"
                      v-model="email"
                      name="email"
                      :class="{ 'is-invalid': isSubmitted && $v.email.$error }"
                    />
                    <div>
                      <span style="color: red; cursor: pointer" v-if="msg.email">{{
                        msg.email
                      }}</span>
                    </div>
                    <div
                      v-if="isSubmitted && $v.email.$error"
                      class="invalid-feedback"
                      style="color: red"
                    >
                      <span v-if="!$v.email.required">Email Address is required</span>
                      <span v-if="!$v.email.maxLength">Max length of 50 characters exceeded</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-xs-6">
                  <div class="form-group">
                    <label for="incorporation_date" class="required-field"
                      >Incorporation Date</label
                    >
                    <input
                      type="date"
                      class="form-control"
                      id="incorporation_date"
                      v-model="incorporation_date"
                      name="incorporation_date"
                      :max="new Date().toISOString().slice(0, 10)"
                    />
                    <div
                      v-if="isSubmitted && $v.incorporation_date.$error"
                      class="invalid-feedback"
                      style="color: red"
                    >
                      <span v-if="!$v.incorporation_date.required"
                        >Incorporation Date is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-4 col-6">
                  <div class="form-group fc">
                    <label for="website" class="required-field"
                      >Website</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      placeholder
                      v-model="website"
                      id="website"
                      name="website"
                      :class="{
                        'is-invalid':
                          (isSubmitted && $v.website.$error) ||
                          msg.website,
                      }"
                    />
                    <div style="color: red; cursor: pointer" v-if="msg.website">
                      <span>{{ msg.website }}</span>
                    </div>
                    <div
                      v-if="isSubmitted && $v.website.$error"
                      class="invalid-feedback"
                      style="color: red"
                    >
                      <span v-if="!$v.website.required"
                        >Website is required</span
                      >
                      <span v-if="!$v.website.maxLength"
                        >Max length of 100 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-xs-6">
                  <div class="form-group fc">
                    <label for="address" class="required-field"
                      >Address</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      placeholder
                      v-model="address"
                      :class="{
                        'is-invalid': isSubmitted && $v.address.$error,
                      }"
                      id="address"
                      name="address"
                    />
                    <div
                      v-if="isSubmitted && $v.address.$error"
                      class="invalid-feedback"
                      style="color: red"
                    >
                      <span v-if="!$v.address.required"
                        >Address is required</span
                      >
                      <span v-if="!$v.address.maxLength"
                        >Max length of 250 characters exceeded</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-xs-6">
                  <div class="form-group">
                    <label for="registration_date" class="required-field"
                      >Registration Date</label
                    >
                    <input
                      type="date"
                      class="form-control"
                      id="registration_date"
                      v-model="registration_date"
                      name="registration_date"
                      :min="new Date(this.incorporation_date).toISOString().slice(0, 10)"
                      :max="new Date().toISOString().slice(0, 10)"
                    />
                    <div
                      v-if="isSubmitted && $v.registration_date.$error"
                      class="invalid-feedback"
                      style="color: red"
                    >
                      <span v-if="!$v.registration_date.required"
                        >Registration Date is required</span
                      >
                    </div>
                  </div>
                </div>
                
              </div>
            </div>


            <div class="row">
              <div class="col-md-12 text-right mb-3">
                <button
                  style="margin-right: 6px"
                  type="button"
                  class="btn btn-info"
                  v-on:click="onSubmit()"
                >
                  Submit
                </button>
                <button
                  type="button"
                  class="btn btn-default"
                  @click="$router.go(-1)"
                  style="margin-right:6px cursor: pointer "
                >
                  Cancel
                </button>
              </div>
            </div>

            <!-- <div class="row" style="margin-top:20px;">
              <div class="col-md-12">
                <div class="alert alert-success alert-dismissible">
                  <a
                    href="#"
                    class="close"
                    data-dismiss="alert"
                    aria-label="close"
                    style="font-size: 42px;font-weight: normal;"
                  >×</a>
                  <ul class="b">
                    <li>Your password can't be too similar to your other personal information.</li>
                    <li>Your Password must contain at least 8 characters.</li>
                    <li>Your password can't be a commonly used password.</li>
                    <li>Your password can't be entirely numeric.</li>
                  </ul>
                </div>
              </div>
            </div>-->
          </div>
        </div>
        <!-- all other page content start -->

        <!-- <div id="footer">
        <div class="container-fluid">
          <div class="pull-right hidden-xs"><b>Version</b> 2.4.0</div>
          Copyright 2021
        </div>
      </div> -->
      </div>
      <!-------------Main container start--------------->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import { required , maxLength } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      //variables for Add User information
      client_name: null,
      registration_date:null,
      email: null,
      website: null,
      address: null,
      incorporation_date:null,
      client_id: null,
      clientDetails: [],
      isSubmitted: false,
      msg: [],
      error: null
    };
  },
  watch: {
    email(value) {
      // binding this to the data value in the email input
      this.email = value;
      this.validateEmail(value);
    },
    website(value) {
      // binding this to the data value in the website input
      this.website = value;
      this.validateWebsite(value);
    }
  },
  computed: {
    //this is to computed disabled property of main Submit button to save information in database.
    // isDisabledOnSubmit: function() {
    //   if (
    //     this.client_name === null ||
    //     this.client_name === "" ||
    //     this.selectedGroupName === null ||
    //     this.selectedGroupName === "" ||
    //     this.password === null ||
    //     this.password === ""
    //   ) {
    //     //here we will check for all sections
    //     return true;
    //   }
    // } //isDisabledOnSubmit
  }, //main computed close
  validations: {
    client_name: {
      maxLength: maxLength(100),
      required: function (val) {
        if ((val === null || val == '' || val == 0))
          return false;
        else return true;
      },
    },
    registration_date: {
      required
    },
    email: {
      maxLength: maxLength(50),
      required,
    },
    address: {
      required,
      maxLength: maxLength(250),
    },
    website: {
      maxLength: maxLength(100),
      required,
    },
    incorporation_date: {
      required
    },
  },
  methods: {
    validateEmail(value) {
      if (/^\w+([\.-]\w+)*@\w+([\.-]\w+)*(\.\w{2,3})+$/.test(value)) {
        this.msg["email"] = "";
      } else {
        this.msg["email"] = "Invalid Email Address";
      }
    },

    validateWebsite(value) {
      if (/^(www\.)([A-Za-z0-9-])+(\.\D{2,3})$/.test(value) || value === "") {
        this.msg["website"] = "";
      } else {
        this.msg["website"] = "Invalid Website";
      }
    },

    getSubGroupName() {
      console.log("GroupName>>" + this.selectedGroupName);
      axios
        .get("subgroupname/" + this.selectedGroupName, {
          params: { client_id: this.$store.state.client_id }
        })
        .then(res => {
          this.SubGroupName = res.data.subgroupnames;
          console.log("SUBGROUPDATA: ", res.data);
        })
        .catch(error => {
          console.error(error);
        });
    },

    getDateTypeFormat() {
      //this function is called to get list of banks
      const path = "date-format-type-master";
      axios
        .get(path)
        .then((res) => {
          console.log(res)
          console.log("DateFormat >>",res.data.date_format_type)
          this.DateFormat = res.data.date_format_type;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //this method will submit the facility form in backend.
    onSubmit() {
      console.log(this.loggedInClientId);
      console.log("*************");
      this.isSubmitted = true;
      this.$v.$touch();
      console.log("!Object.values(this.msg).every((e) => e === ''",!Object.values(this.msg).every((e) => e === ""))
      console.log("this.$v.$invalid",this.$v.$invalid)
      if (this.$v.$invalid || !Object.values(this.msg).every((e) => e === "")) {
        return;
      }

      console.log(this.registration_date)
      if (this.registration_date != null) {
        this.registration_date = moment(this.registration_date,"YYYY-MM-DD").format("MM-DD-YYYY");
      }
      if (this.incorporation_date != null) {
        this.incorporation_date = moment(this.incorporation_date,"YYYY-MM-DD").format("MM-DD-YYYY");
      }

      //initialize the form data
      let formData = new FormData();
      //append company information to formdata
      formData.append("client_name", this.client_name);
      formData.append("registration_date", this.registration_date);
      formData.append("email", this.email);
      formData.append("website", this.website);
      formData.append("address", this.address);
      formData.append("incorporation_date", this.incorporation_date);
      const path = "/add_client/" + this.$store.state.client_id;
      Utils.start_loading();
      axios
        .post(path, formData, {
          headers: {
            enctype: "multipart/form-data"
          }
        })
        .then(response => {
          Utils.stop_loading();
          console.log("status: " + response.data.status);
          if (response.data.status == "success") {
            Utils.make_alert("success", "Client added successfully!");
            console.log("SUCCESS!!");
            this.$router.push("/permission");
          } else if (response.data.status == "client_already_exists") {
            Utils.make_alert("warning", "Client already exists!");
            this.$router.push("/permission");
          } else {
            Utils.make_alert("success", "FAILURE!!! Something went wrong!");
          }
        })
        .catch(error => {
          Utils.stop_loading();
          console.log("FAILURE!! In catch");
          console.error(error);
        });
    }, //onSubmit() close

    getClientMaster() {
      //this function is called to get list of banks
      const path = "clientMaster";
      axios
        .get(path)
        .then((res) => {
          console.log(res)
          console.log("DateFormat >>",res.data.ClientMaster)
          this.clientDetails = res.data.clientDetails;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  created() {
    this.getClientMaster()
  },
  computed: {
    loggedInClientId() {
      var clientId = localStorage.getItem("client_id");
      console.log(clientId);
      return clientId;
    }
  }
};
</script>

<style scoped>
.whitepatch .form-group .form-control,
.input-group-addon {
  background-color: #f0f3f5 !important;
  border: none !important;
  height: 42px;
  color: #000 !important;
  box-shadow: none !important;
  margin: 0px;
  padding: 0px 14px !important;
}
.required-field::after {
  content: "*";
  color: red;
}
/* select.form-control:not([size]):not([multiple]) {
    height: calc(2.25rem + 2px);
}

.fc .form-control {
    border: none;
    padding: 4px 8px;
    height: 32px;
    font-size: 14px;
    background-color: #EEEEF0;
    border-bottom: 2px solid #DDDDE4;
    border-radius: 0px;
    box-shadow: none !important;
} */
</style>
