import axios from "axios";
//import { createStore } from "vuex";
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex)
//export const store = createStore({
export const store = new Vuex.Store({
    state: {
        loggedInUserFullName: null,
        loggedInUser: {},
        user_id: null,
        loggedInUserName: localStorage.getItem("username") || null,
        token: sessionStorage.getItem("token") || null,
        group_name: localStorage.getItem("group_name") || null,
        subgroup_id: localStorage.getItem("subgroup_id") || null,
        client_id: localStorage.getItem("client_id") || null,
        date_format_type: localStorage.getItem("date_format_type") || null,
        date_format: localStorage.getItem("date_format") || null,
        moment_date_format: localStorage.getItem("moment_date_format") || null,

    },
    getters: {
        loggedIn(state) {
            return state.token !== null && state.group_name !== null && state.subgroup_id !== null && state.client_id !== null
        },
        getuser(state) {
            return state.loggedInUser
        },
        getuserFullName(state) {
            return state.loggedInUser.username
        },
        getuserGroup(state) {
            console.log(state.group_name)
            return state.group_name
        },
        getuserSubGroup(state) {
            console.log(state.subgroup_id)
            return state.subgroup_id
        },
        getuserId(state) {
            console.log(state.user_id)
            return state.user_id
        },
        getclientId(state) {
            return state.client_id
        },
    },
    mutations: {
        retrieveToken(state, token, group_name, subgroup_id,client_id) {
            state.token = token;
            state.group_name = group_name;
            state.subgroup_id = subgroup_id;
            state.client_id = client_id;
            // state.user_id = user_id;
        },
        retrieveAdminToken(state, token, group_name, subgroup_id,client_id) {
            state.token = token;
            state.group_name = group_name;
            state.subgroup_id = subgroup_id;
            state.client_id = client_id;
            // state.user_id = user_id;
        },
        destroyToken(state) {
            state.token = null;
            state.group_name = null;
            state.subgroup_id = null;
            state.user_id = null;
            state.client_id = null;
        },
        destroyAdminToken(state) {
            state.token = null;
            state.group_name = null;
            state.subgroup_id = null;
            state.user_id = null;
            state.client_id = null;
        },
        destroyloggedInUser(state) {
            state.loggedInUser = {}
        },
        setLoginFailed(state, error) {
            state.error = error
        },
        setloggedInUser(state, user) {
            state.loggedInUser = user
        },
        setloggedInUserFullName(state, user) {
            state.loggedInUserFullName = user.first_name + " " + user.last_name
        },
        setuserGroup(state, group_name) {
            console.log(state.group_name)
            state.group_name = group_name
            return state.group_name
        },
        setuserSubGroup(state, subgroup_id) {
            console.log(state.subgroup_id)
            state.subgroup_id = subgroup_id
            return state.subgroup_id
        },
        setuserId(state, user_id) {
            console.log(state.user_id)
            state.user_id = user_id;
        },
        setclientId(state, client_id) {
            console.log(state.client_id)
            state.client_id = client_id;
        },
        setDateFormatType(state, date_format_type) {
        state.date_format_type = date_format_type;
        },
        setDateFormat(state, date_format) {
            state.date_format = date_format;
        },
        setMomentDateFormat(state, moment_date_format) {
            state.moment_date_format = moment_date_format;
        },
    },
    actions: {
        retrieveToken(context, credentials) {
            return new Promise((resolve, reject) => {
                axios.post("login", credentials)
                    .then(response => {
                        console.log(response.data)
                        const token = response.data.token;
                        const group_name = response.data.group_name;
                        const subgroup_id = response.data.subgroup_id;
                        const user_id = response.data.user_id;
                        const client_id = response.data.client_id;
                        sessionStorage.setItem("token", response.data.token);
                        localStorage.setItem("group_name", response.data.group_name);
                        localStorage.setItem("subgroup_id", response.data.subgroup_id);
                        localStorage.setItem("username", response.data.username);
                        localStorage.setItem("client_id", response.data.client_id);
                        context.commit('retrieveToken', token, group_name, subgroup_id)
                        context.commit('setuserId', user_id)
                        resolve(response)
                    })
                    .catch(err => {
                        sessionStorage.removeItem('token')
                        localStorage.removeItem('group_name')
                        localStorage.removeItem('subgroup_id')
                        localStorage.removeItem('client_id')
                        console.error(err)
                        console.log(err)
                        reject("Wrong email or password")
                        reject(err)
                    })

            })
        },
        retrieveAdminToken(context, credentials) {
            console.log("Here In retrieveToken", context, credentials)
            return new Promise((resolve, reject) => {
                console.log("Here In Admin Promise")
                axios.post("admin_login", credentials)
                    .then(response => {
                        console.log(response.data)
                        const token = response.data.token;
                        const group_name = response.data.group_name;
                        const subgroup_id = response.data.subgroup_id;
                        const user_id = response.data.user_id;
                        const client_id = response.data.client_id;
                        const date_format_type = response.data.date_format_type_master_id;
                        const moment_date_format = response.data.moment_date_format;
                        const datepicker_date_format = response.data.datepicker_date_format;
                        if(date_format_type != null){
                            context.commit("setDateFormatType", date_format_type);
                            context.commit("setDateFormat", datepicker_date_format);
                            context.commit("setMomentDateFormat", moment_date_format);
                            localStorage.setItem("date_format_type", date_format_type);
                            localStorage.setItem("date_format", datepicker_date_format);
                            localStorage.setItem("moment_date_format", moment_date_format);
                        } else {
                            context.commit("setDateFormatType", date_format_type);
                            context.commit("setDateFormat", "dd-MM-yyyy");
                            context.commit("setMomentDateFormat", "DD-MM-YYYY");
                            localStorage.setItem("date_format_type", date_format_type);
                            localStorage.setItem("date_format", "dd-MM-yyyy");
                            localStorage.setItem("moment_date_format", "DD-MM-YYYY");
                        }
                        sessionStorage.setItem("token", response.data.token);
                        localStorage.setItem("group_name", response.data.group_name);
                        localStorage.setItem("subgroup_id", response.data.subgroup_id);
                        localStorage.setItem("username", response.data.username);
                        localStorage.setItem("client_id", response.data.client_id);
                        context.commit('retrieveAdminToken', token, group_name, subgroup_id)
                        context.commit('setuserId', user_id)
                        context.commit('setclientId', client_id)
                        axios.defaults.headers.common["Authorisation"] ="Bearer " + sessionStorage.getItem("token");
                        resolve(response)
                    })
                    .catch(err => {
                        sessionStorage.removeItem('token')
                        localStorage.removeItem('group_name')
                        localStorage.removeItem('subgroup_id')
                        localStorage.removeItem('client_id')
                        console.error(err)
                        console.log(err)
                        if (err.response.status == 401) {
                            reject("Unauthorised User")
                        } else {
                            reject("Wrong email or password")
                        }
                        reject(err)
                    })
            })
        },
        destroyToken(context) {
            // Logout the user
            // Remove token from local storage
            // Remove professional details data

            if (context.getters.loggedIn) {
                sessionStorage.removeItem('token')
                localStorage.removeItem('group_name')
                localStorage.removeItem('subgroup_id')
                localStorage.removeItem('client_id')
                context.commit('destroyloggedInUser')
                context.commit('destroyToken')
                console.log("STATE AFTER LOGOUT", this.$state)
            }
        },
        destroyAdminToken(context) {
            // Logout the user
            // Remove token from local storage
            // Remove professional details data

            if (context.getters.loggedIn) {
                sessionStorage.removeItem('token')
                localStorage.removeItem('group_name')
                localStorage.removeItem('subgroup_id')
                localStorage.removeItem('client_id')
                context.commit('destroyloggedInUser')
                context.commit('destroyAdminToken')
                console.log("STATE AFTER LOGOUT", this.$state)
            }
        },

        getLoggedInUser(context) {
            console.log("Here In getLoggedInUser", context)
            return new Promise((resolve, reject) => {
                axios.get("login/")
                    .then(response => {
                        console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$" + response.data)
                        context.commit('setloggedInUser', response.data.username)
                        console.log(response.data)
                        context.commit('setuserGroup', response.data.group_name)
                        context.commit('setuserSubGroup', response.data.subgroup_id)
                        context.commit('setclientId', response.data.client_id)
                        context.commit('setloggedInUser', username)
                        // context.commit('setloggedInUserFullName', response.data)
                        axios.defaults.headers.common['Authorization'] = response.data.token
                        // axios.defaults.headers.common['Authorization'] = response.data.token
                        resolve(response)
                    })
                    .catch(err => {
                        console.log("ERRROR: " + err)
                        reject(err)
                    })
            })
        },
        getLoggedInUserAdmin(context) {
            console.log("Here In getLoggedInUser", context)
            return new Promise((resolve, reject) => {
                axios.get("admin_login/")
                    .then(response => {
                        console.log(response.data)
                        context.commit('setloggedInUser', response.data.username)
                        console.log(response.data)
                        context.commit('setuserGroup', response.data.group_name)
                        context.commit('setuserSubGroup', response.data.subgroup_id)
                        context.commit('setloggedInUser', username)
                        context.commit('setclientId', response.data.client_id)
                        // context.commit('setloggedInUserFullName', response.data)
                        axios.defaults.headers.common['Authorization'] = response.data.token
                        // axios.defaults.headers.common['Authorization'] = response.data.token
                        resolve(response)
                    })
                    .catch(err => {
                        console.log("ERRROR: " + err)
                        reject(err)
                    })
            })
        },
    },
    plugins: [createPersistedState()],
});
