<template>
  <body class="loginbg">
    <div class="main">
      <center>
        <img src="asset/images/lg_logo.png" class="img-responsive login_logo" />
      </center>
      <form @submit.prevent="handleSubmit()">
        <div v-if="message" class="alert alert-success" role="alert">{{ message }}</div>
        <error v-if="error" :error="error" />
        <div class="form-group">
          <input
            type="password"
            class="form-control"
            v-model="password"
            id="password"
            name="password"
            :class="{ 'is-invalid': isSubmitted && $v.password.$error }"
            placeholder="Password"
          />

          <div class="toooltip" style="float:right; color: #c0c0c0;">
            Password Guidelines
            <span class="tooltiptext">
              Minimum 6 Characters <br />
              Maximum 20 Characters <br />
              At least 1 Number <br />
              At least 1 Uppercase <br />
              At least 1 Lowercase <br />
              At least 1 Special Character <br />
            </span>
          </div>

          <div
            v-if="isSubmitted && $v.password.$error"
            class="invalid-feedback"
            style="color: red;"
          >
            <span v-if="!$v.password.required">Password field is required</span>
            <span v-if="$v.password.required && !$v.password.minLength"
              >Password should be at least 6 characters long</span
            >
            <span v-if="$v.password.required && !$v.password.maxLength"
              >Password should not be more than 20 characters long</span
            >
            <span
              v-if="
                $v.password.required &&
                  $v.password.minLength &&
                  $v.password.maxLength &&
                  !$v.password.valid
              "
              >Password must have atleast One Uppercase, One Lowercase, One Number and One Special
              Character</span
            >
          </div>
        </div>
        <div class="form-group">
          <input
            type="password"
            class="form-control"
            v-model="password_confirm"
            id="password_confirm"
            name="password_confirm"
            :class="{ 'is-invalid': isSubmitted && $v.password_confirm.$error }"
            placeholder="Confirm Password"
          />
          <div v-if="isSubmitted && $v.password_confirm.$error" class="invalid-feedback">
            <span v-if="!$v.password_confirm.required">Confirm Password field is required </span>
            <span v-if="!$v.password_confirm.sameAsPassword && $v.password_confirm.required"
              >Passwords should be matched</span
            >
          </div>
        </div>

        <!-- <div class="custom-control custom-checkbox mb-3">
              <input type="checkbox" class="custom-control-input" id="customCheck" name="example1" />
              <label class="custom-control-label" for="customCheck">Remember me!</label>
            </div>-->

        <!-- <router-link
              to="/login"
              type="button"
              class="btn btn-block btn-primary btn-lg sh lg_btn"
            >Change Password</router-link>-->

        <a
          type="button"
          class="btn btn-block btn-primary btn-lg sh lg_btn"
          style="color: white;"
          v-on:keyup.enter="handleSubmit"
          @click="handleSubmit"
        >
          Change Password
        </a>
      </form>
    </div>
  </body>
</template>

<script>
import axios from "axios";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import { required, minLength, sameAs, maxLength } from "vuelidate/lib/validators";
export default {
  name: "ChangePassword",
  data() {
    return {
      password: "",
      password_confirm: "",
      isSubmitted: false,
      message: null,
      error: ""
    };
  },
  validations: {
    password: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(20),
      valid: function(value) {
        const containsUppercase = /[A-Z]/.test(value);
        const containsLowercase = /[a-z]/.test(value);
        const containsNumber = /[0-9]/.test(value);
        const containsSpecial = /[#?!@$%^&*-]/.test(value);
        return containsUppercase && containsLowercase && containsNumber && containsSpecial;
      }
    },
    password_confirm: {
      required,
      sameAsPassword: sameAs("password")
    }
  },
  methods: {
    async handleSubmit() {
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      try {
        const response = await axios.post(
          //"https://tradepulseapi.algocircle.com/change_password/" + this.token,
          "change_password/" + this.token,
          {
            password: this.password,
            password_confirm: this.password_confirm,
            token: this.$route.params.token
          }
        );
        this.message = "Password Change Successful";
        this.error = "";
        Utils.make_alert("success", "Password Change Successful!");
        console.log(response);
        this.$router.push({ name: "AdminLogin" });
        // this.$router.push("/login");
      } catch (e) {
        console.log("ERRORRRR");
        //this.error = "Error Occured";
        this.message = "";
      }
    }
  }
};
</script>

<style scoped>
.toooltip {
  font-size: 12px;
  position: relative;
  display: inline-block;
}

.toooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: rgb(6, 56, 90);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 110%;
}

.toooltip .tooltiptext {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent;
  opacity: 0;
  transition: opacity 2s;
}
.toooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.brand-text {
  text-align: center;
  color: white;
}

.brand-text span {
  font-size: 35px;
  color: white;
  padding-left: 10px;
}
</style>
