<template>
  <body class="loginbg">
    <div class="main">
      <center>
        <img src="/asset/images/AdminLTELogo.png" class="img-fluid login_logo" />
      </center>

      <form @submit.prevent="handleSubmit()">
        <div v-if="message" class="alert alert-success" role="alert">{{ message }}</div>
        <div v-if="error" class="alert alert-danger" role="alert">
          {{ error }}
          <!-- <error v-if="error" :error="error"/> -->
        </div>
        <div class="form-group">
          <input
            type="email"
            class="form-control"
            v-model="email"
            id="email"
            name="email"
            :class="{ 'is-invalid': isSubmitted && $v.email.$error }"
            placeholder="Enter Email Address.."
          />
          <div v-if="isSubmitted && !$v.email.error" class="invalid-feedback" style="color: red;">
            <span v-if="!$v.email.email">Please provide valid email</span>
            <span v-if="!$v.email.required">Email field is required</span>
          </div>
        </div>

        <!-- <router-link
              to="/login"
              type="button"
              class="btn btn-block btn-primary btn-lg sh lg_btn"
        >Send</router-link>-->
        <a
          type="button"
          class="btn btn-block btn-primary btn-lg sh lg_btn"
          style="color: white;"
          v-on:keyup.enter="handleSubmit"
          @click="handleSubmit"
          >Send</a
        >
      </form>
    </div>
  </body>
</template>

<script>
import axios from "axios";
import { required, email } from "vuelidate/lib/validators";
import Error from "./Error.vue";

export default {
  name: "ForgotPassword",
  components: {
    Error
  },
  data() {
    return {
      email: "",
      message: "",
      error: "",
      isSubmitted: false
    };
  },
  validations: {
    email: {
      required,
      email
    }
  },
  methods: {
    handleSubmit() {
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      const response = axios
        .post("forgot_password", {
          email: this.email
        })
        .then(response => {
          // alert('The Email Request Was sent');
          console.log(this.email);
          // this.$router.push({ name: "login" });
          console.log(response);
          this.message = "Email has been sent";
          this.error = "";
        })
        .catch(error => {
          // alert("Incorrect Email!");
          this.error = "Invalid Email";
          this.message = "";
          this.$router.push({ name: "ForgotPassword" });
        });
    }
  }
};
</script>

<style scoped>
.brand-text {
  text-align: center;
  color: white;
}

.brand-text span {
  font-size: 35px;
  color: white;
  padding-left: 10px;
}
</style>
