//this is index.js -> it is entry point for all the vuejs routers.
import Vue from "vue";
import VueRouter from "vue-router";
import { store } from "@/store";

// Admin pages routing
import AdminLogin from "@/views/admin/AdminLogin.vue";
import AdminLogout from "@/views/admin/AdminLogout.vue";
import Unauthorised from "@/components/auth/Unauthorised.vue";
import AddUser from "@/views/admin/AddUser.vue";
import AddClient from "@/views/admin/AddClient.vue";
import AddGroup from "@/views/admin/AddGroup.vue";
import UserView from "@/views/admin/UserView.vue";
import GroupView from "@/views/admin/GroupView.vue";
import UpdateGroup from "@/views/admin/UpdateGroup.vue";
import Permission from "@/views/admin/Permission.vue";
import MetaTable from "@/views/admin/MetaTable.vue";
import Benchmark from "@/views/admin/table_masters/Benchmark.vue";
import City from "@/views/admin/table_masters/City.vue";
import Covenants from "@/views/admin/table_masters/Covenants.vue";
import Document from "@/views/admin/table_masters/Document.vue";
import LoanExpenseMaster from "@/views/admin/table_masters/LoanExpenseMaster.vue";
import LoanTypeMaster from "@/views/admin/table_masters/LoanTypeMaster.vue";
import State from "@/views/admin/table_masters/State.vue";
import DocumentTypeMaster from "@/views/admin/table_masters/DocumentTypeMaster.vue";
import DayCount from "@/views/admin/table_masters/DayCount.vue";
import Country from "@/views/admin/table_masters/Country.vue";
// import BorrowerKyc from "@/views/admin/table_masters/BorrowerKyc.vue"
import Expense from "@/views/admin/table_masters/Expense.vue";
// import Status from "@/views/admin/table_masters/Status.vue"
import BorrowerCovenant from "@/views/admin/table_masters/BorrowerCovenant.vue";
import Currency from "@/views/admin/table_masters/Currency.vue";
import InterestFrequency from "@/views/admin/table_masters/InterestFrequency.vue";
import UnitType from "@/views/admin/table_masters/UnitType.vue";
import CollateralQuality from "@/views/admin/table_masters/CollateralQuality.vue";
import ContactCode from "@/views/admin/table_masters/ContactCode.vue";
import BusinessType from "@/views/admin/table_masters/BusinessType.vue";
import Port from "@/views/admin/table_masters/Port.vue";

import UpdateUser from "@/views/admin/UpdateUser.vue";
import ForgotPassword from "@/components/auth/ForgotPassword.vue";
import ChangePassword from "@/components/auth/ChangePassword.vue";
// import Error from "@/components/auth/Error.vue"

Vue.use(VueRouter);

const routes = [
  //admin portal
  {
    path: "/admin_login",
    name: "AdminLogin",
    component: AdminLogin,
    meta: {
      requiresGuest: true
    }
  },
  {
    path: "/admin_logout",
    name: "AdminLogout",
    component: AdminLogout,
    meta: {
      requiresGuest: true
    }
  },
  {
    path: "/unauthorised",
    name: "Unauthorised",
    component: Unauthorised
  },
  {
    path: "/add_client",
    name: "AddClient",
    component: AddClient,
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (localStorage.getItem("group_name") != "Super Admin") {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    }
  },
  {
    path: "/add_user",
    name: "AddUser",
    component: AddUser,
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (localStorage.getItem("group_name") == "Admin" || localStorage.getItem("group_name") == "Super Admin") {
        next();
      } else {
        next({ name: "Unauthorised" });
      }
    }
  },
  {
    path: "/update_user/:id/:client_id",
    name: "UpdateUser",
    component: UpdateUser,
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (localStorage.getItem("group_name") == "Admin" || localStorage.getItem("group_name") == "Super Admin") {
        next();
      } else {
        next({ name: "Unauthorised" });
      }
    }
  },
  {
    path: "/add_group",
    name: "AddGroup",
    component: AddGroup,
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (localStorage.getItem("group_name") != "Super Admin") {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    }
  },
  {
    path: "/update_group/:id",
    name: "UpdateGroup",
    component: UpdateGroup,
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (localStorage.getItem("group_name") != "Super Admin") {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    }
  },
  {
    path: "/user_view",
    name: "UserView",
    component: UserView,
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (localStorage.getItem("group_name") != "Super Admin") {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    }
  },
  {
    path: "/group_view/:id",
    name: "GroupView",
    component: GroupView,
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (localStorage.getItem("group_name") != "Super Admin") {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    }
  },
  {
    path: "/permission",
    name: "Permission",
    component: Permission,
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (localStorage.getItem("group_name") == "Admin" || localStorage.getItem("group_name") == "Super Admin") {
        next();
      } else {
        next({ name: "Unauthorised" });
      }
    }
  },
  {
    path: "/",
    name: "Permission",
    component: Permission,
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (localStorage.getItem("group_name") == "Admin" || localStorage.getItem("group_name") == "Super Admin") {
        next();
      } else {
        next({ name: "Unauthorised" });
      }
    }
  },
  {
    path: "/table/:master_table",
    name: "MetaTable",
    component: MetaTable,
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (localStorage.getItem("group_name") != "Super Admin") {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    }
  },
  {
    path: "/forgot_password",
    name: "ForgotPassword",
    component: ForgotPassword
  },
  {
    path: "/change_password/:token",
    name: "ChangePassword",
    component: ChangePassword
  },
  {
    path: "/benchmark",
    name: "Benchmark",
    component: Benchmark,
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (localStorage.getItem("group_name") != "Super Admin") {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    }
  },
  {
    path: "/daycount",
    name: "DayCount",
    component: DayCount,
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (localStorage.getItem("group_name") != "Super Admin") {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    }
  },
  {
    path: "/admin-loan-expense-master",
    name: "LoanExpenseMaster",
    component: LoanExpenseMaster,
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (localStorage.getItem("group_name") != "Super Admin") {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    }
  },
  {
    path: "/admin-loan-type",
    name: "LoanTypeMaster",
    component: LoanTypeMaster,
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (localStorage.getItem("group_name") != "Super Admin") {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    }
  },
  {
    path: "/admin-document-type",
    name: "DocumentTypeMaster",
    component: DocumentTypeMaster,
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (localStorage.getItem("group_name") != "Super Admin") {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    }
  },
  {
    path: "/admin-state",
    name: "State",
    component: State,
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (localStorage.getItem("group_name") != "Super Admin") {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    }
  },
  {
    path: "/country",
    name: "Country",
    component: Country,
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (localStorage.getItem("group_name") != "Super Admin") {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    }
  },
  {
    path: "/city",
    name: "City",
    component: City,
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (localStorage.getItem("group_name") != "Super Admin") {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    }
  },
  {
    path: "/covenants",
    name: "Covenants",
    component: Covenants,
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (localStorage.getItem("group_name") != "Super Admin") {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    }
  },
  {
    path: "/admin-documents",
    name: "Document",
    component: Document,
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (localStorage.getItem("group_name") != "Super Admin") {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    }
  },
  {
    path: "/expense",
    name: "Expense",
    component: Expense,
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (localStorage.getItem("group_name") != "Super Admin") {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    }
  },
  {
    path: "/borrowercovenant",
    name: "BorrowerCovenant",
    component: BorrowerCovenant,
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (localStorage.getItem("group_name") != "Super Admin") {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    }
  },
  {
    path: "/currency",
    name: "Currency",
    component: Currency,
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (localStorage.getItem("group_name") != "Super Admin") {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    }
  },
  {
    path: "/interestfrequency",
    name: "InterestFrequency",
    component: InterestFrequency,
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (localStorage.getItem("group_name") != "Super Admin") {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    }
  },
  {
    path: "/unitype",
    name: "UnitType",
    component: UnitType,
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (localStorage.getItem("group_name") != "Super Admin") {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    }
  },
  {
    path: "/collateralquality",
    name: "CollateralQuality",
    component: CollateralQuality,
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (localStorage.getItem("group_name") != "Super Admin") {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    }
  },
  {
    path: "/contact-code",
    name: "ContactCode",
    component: ContactCode,
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (localStorage.getItem("group_name") != "Super Admin") {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    }
  },
  {
    path: "/business-type",
    name: "BusinessType",
    component: BusinessType,
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (localStorage.getItem("group_name") != "Super Admin") {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    }
  },
  {
    path: "/port",
    name: "Port",
    component: Port,
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      console.log(localStorage.getItem("group_name"));
      if (localStorage.getItem("group_name") != "Super Admin") {
        next({ name: "Unauthorised" });
      } else {
        next();
      }
    }
  }
];

const router = new VueRouter({
  
  mode: "history", //needs to be enabled on production
  base: process.env.BASE_URL,
  routes
});

export default router;
