<template>
<body class="loginbg">
  <div class="main">
    <center>
      <img src="asset/images/lg_logo.png" class="img-responsive login_logo" />
    </center>
    <form @submit.prevent="submitLogin()" class="form1">
      <div v-if="error" class="alert alert-danger" role="alert">
        {{ error }}
        <!-- <error v-if="error" :error="error"/> -->
      </div>
      <input
        class="un"
        type="email"
        v-model="email"
        id="email"
        name="email"
        :class="{ 'is-invalid': isSubmitted && $v.email.$error }"
        align="center"
        placeholder="Email"
      />
      <div v-if="isSubmitted && !$v.email.error" class="invalid-feedback" style="color: red;">
        <span v-if="!$v.email.email">Please provide valid email</span>
        <span v-if="!$v.email.required">Email field is required</span>
      </div>
      <input
        class="pass"
        type="password"
        v-model="password"
        id="password"
        name="password"
        align="center"
        :class="{ 'is-invalid': isSubmitted && $v.password.$error }"
        placeholder="Password"
      />
      <div v-if="isSubmitted && $v.password.$error" class="invalid-feedback" style="color: red;">
        <span v-if="!$v.password.required">Password field is required</span>
        <span
          v-if="$v.password.required && !$v.password.minLength"
        >Password should be at least 6 characters long</span>
        <span
          v-if="$v.password.required && !$v.password.maxLength"
        >Password should not be more than 20 characters long</span>
        <span
          v-if="
              $v.password.required &&
                $v.password.minLength &&
                $v.password.maxLength &&
                !$v.password.valid
            "
          >
            Password must have atleast One Uppercase, One Lowercase, One Number and One Special
            Character
          </span>
        </div>
        <center>
          <button
            type="button"
            class="btn btn-success loginbtn"
            style="color: white"
            @click="submitLogin"
          >
            Login
          </button>
        </center>
        <p class="forgot" align="center">
          <router-link to="/forgot_password">
            <i class="icofont-key"></i> Forgot Password?
          </router-link>
        </p>
      </form>
    </div>
  </body>
</template>

<script>
import axios from "axios";
// import moment from "moment";
import { required, email, minLength, maxLength } from "vuelidate/lib/validators";
export default {
  name: "AdminLogin",
  // components: { Error },
  data() {
    return {
      email: "",
      password: "",
      // remember_me: false,
      error: "",
      message: "",
      authError: false,
      isSubmitted: false
    };
  },
  validations: {
    email: {
      required,
      email
    },
    password: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(20),
      valid: function(value) {
        const containsUppercase = /[A-Z]/.test(value);
        const containsLowercase = /[a-z]/.test(value);
        const containsNumber = /[0-9]/.test(value);
        const containsSpecial = /[#?!@$%^&*-]/.test(value);
        return containsUppercase && containsLowercase && containsNumber && containsSpecial;
      }
    }
  },
  // created() {
  //   window.addEventListener("beforeunload", this.removeUser);
  // },
  created() {
    // does the browser support the Navigation Timing API?
    if (window.performance) {
      console.info("window.performance is supported");
    }
    // do something based on the navigation type...
    if (performance.navigation.type === 1) {
      console.info("TYPE_RELOAD");
      // this.removeUser();
    }
    window.onunload = function() {
      //logout code here...
      this.$store.dispatch("destroyAdminToken");
      localStorage.clear();
      sessionStorage.clear();
    };
  },
  methods: {
    // submitLogin() {
    //   // this.isSubmitted = true;
    //   // this.$v.$touch();
    //   // if (this.$v.$invalid) {
    //   //   return;
    //   // }
    //   // this.$store
    //   //   .dispatch("retrieveAdminToken", {
    //   //     username: this.email,
    //   //     password: this.password
    //   //   })
    //   let formData = new FormData();
    //   //append company information to formdata
    //   formData.append("email", this.email);
    //   formData.append("password", this.password);
    //   const path = "/adminlogin";
    //   axios
    //     .post(path, formData, {
    //       headers: {
    //         enctype: "multipart/form-data"
    //       }
    //     })
    //     .then(response => {
    //       console.log(response);
    //       // Swal.fire(
    //       //   'Signed In Successfull',
    //       //   '',
    //       //   'success'
    //       // )
    //       // Utils.make_alert("success", "SUCCESS!! :-)\n\n" + JSON.stringify(this.email));
    //       this.$router.push({ name: "Permission" });
    //     })
    //     .catch(error => {
    //       this.error = "Invalid Email or Password!";
    //       // this.$store.commit("setLoginFailed", { error });
    //       this.logginError = error;
    //       this.$router.push({ name: "AdminLogin" });
    //     });
    // }
    submitLogin() {
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$store
        .dispatch("retrieveAdminToken", {
          username: this.email,
          password: this.password
        })
        .then(response => {
          console.log(response);
          this.$router.push({ name: "Permission" });
        })
        .catch(error => {
          if (error == "Unauthorised User") {
            this.error = "Unauthorised User";
          } else {
            this.error = "Invalid Email or Password!";
          }
          this.$store.commit("setLoginFailed", { error });
          this.logginError = error;
          this.$router.push({ name: "AdminLogin" });
        });
    }
  },
  mounted() {
    $("#email , #password").keypress(function(e) {
      var key = e.which;
      if (key == 13) {
        // the enter key code
        console.log("LOGIN...");
        $(".loginbtn").click();
      }
    });
  }
};
</script>

<style scoped>
.brand-text {
  text-align: center;
  color: white;
}

.brand-text span {
  font-size: 35px;
  color: white;
  padding-left: 10px;
}
</style>
