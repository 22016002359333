<template>
  <div>
    <div class="se-pre-con"></div>
    <div id="page-container">
      <!-------------Main container start--------------->

      <div id="content-wrap">
        <!-- all other page content start -->
        <admin-nav-bar></admin-nav-bar>

        <div class="container-fluid" id="headerbx" style="background-image:url(assets/tp_bg.png);">
          <div class="container-fluid filtersearch">
            <div class="row">
              <div class="col-md-12">
                <div class="headingbg">
                  <h5>
                    <img
                      src="asset/images/summary.png"
                      class="img-responsive heading_image"
                      align="left"
                    />
                    Heading
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <MasterTable :table_name="table_name"></MasterTable>
        <!-- all other page content start -->

        <div id="footer">
          <div class="container-fluid">
            <div class="pull-right hidden-xs">
              <b>Version</b> 2.4.0
            </div>Copyright 2021
            <a href="https://www.lundyinvestors.com/" target="_blank">LUNDY INVESTORS</a>
          </div>
        </div>
      </div>
      <!-------------Main container start--------------->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import MasterTable from "@/views/admin/MasterTable.vue";

export default {
  components: { MasterTable },
  data() {
    return {
      //variables for Add User information
      table_name: this.$route.params.master_table,
      UserList: []
    };
  },
  methods: {
    //this method will submit the facility form in backend.
    getTableData() {
      //this function is called on page load and created events. it will show details on fund with fundid equal to this.id
      const path = "permission/";
      axios
        .get(path)
        .then(res => {
          console.log(res.data);
          this.UserList = res.data.permission.UserList;
          if (res.data.status == "success") {
            console.log("get fund details is successful.");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch(error => {
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    } //getFund close
  }
};
</script>

<style></style>
