<template>
  <div>
    <div id="page-container">
      <!-------------Main container start--------------->
      <div id="content-wrap">
        <!-- all other page content start -->
        <admin-nav-bar></admin-nav-bar>

        <div
          class="container-fluid"
          id="headerbx"
          style="background-image: url(asset/images/tp_bg.png)"
        >
          <div class="container-fluid filtersearch">
            <div class="row">
              <div class="col-md-12">
                <div class="headingbg">
                  <h5>
                    <img
                      src="asset/images/summary.png"
                      class="img-responsive heading_image"
                      align="left"
                    />Add User
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid" id="contentmaindiv">
          <div class="whitepatch dualbox">
            <div class="fr_b">
              <div class="row">
                <div class="col-md-2 col-xs-6">
                  <div class="form-group">
                    <label for="text" class="required-field">User Name</label>
                    <input
                      type="text"
                      class="form-control"
                      id="UserName"
                      name="UserName"
                      :class="{
                        'is-invalid': isSubmitted && $v.UserName.$error
                      }"
                      v-model="UserName"
                    />
                    <div
                      v-if="isSubmitted && $v.UserName.$error"
                      class="invalid-feedback"
                      style="color: red"
                    >
                      <span v-if="!$v.UserName.required">User Name is required</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-xs-6">
                  <div class="form-group">
                    <label for="email" class="required-field">Email Address</label>
                    <input
                      type="email"
                      class="form-control"
                      id="email"
                      v-model="email"
                      name="email"
                      :class="{ 'is-invalid': isSubmitted && $v.email.$error }"
                    />
                    <small>
                      <span style="color: red; cursor: pointer" v-if="msg.email">{{
                        msg.email
                      }}</span>
                    </small>
                    <div
                      v-if="isSubmitted && $v.email.$error"
                      class="invalid-feedback"
                      style="color: red"
                    >
                      <span v-if="!$v.email.required">Email Address is required</span>
                    </div>
                  </div>
                </div>

                <div v-if="loggedInUserGroupId === 'Super Admin'" class="col-md-2 col-xs-6">
                  <div class="form-group">
                    <label class="required-field">Client Name</label>
                    <div class="line"></div>
                    <select
                      id="selectedClientID"
                      class="form-control"
                      name="selectedClientID"
                      v-model="selectedClientID"
                      :class="{
                        'is-invalid': isSubmitted && $v.selectedClientID.$error
                      }"
                    >
                      <option :value="null" disabled>--- Select ---</option>
                      <option
                        v-for="cl in clientDetails"
                        :key="cl.client_id"
                        :value="cl.client_id"
                      >
                        {{ cl.client_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedClientID.$error"
                      class="invalid-feedback"
                      style="color: red"
                    >
                      <span v-if="!$v.selectedClientID.required">Client Name is required</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-xs-6">
                  <div class="form-group">
                    <label class="required-field">Group Name</label>
                    <div class="line"></div>
                    <select
                      id="selectedGroupName"
                      class="form-control"
                      name="selectedGroupName"
                      v-model="selectedGroupName"
                      @change="getSubGroupName"
                      :class="{
                        'is-invalid': isSubmitted && $v.selectedGroupName.$error
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="grp_name in GroupName"
                        :key="grp_name.id"
                        :value="grp_name.group_name"
                      >
                        {{ grp_name.group_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedGroupName.$error"
                      class="invalid-feedback"
                      style="color: red"
                    >
                      <span v-if="!$v.selectedGroupName.required">Group Name is required</span>
                    </div>
                  </div>
                </div>

                <div
                  v-if="
                    selectedGroupName == 'Investor' ||
                      selectedGroupName == 'Borrower' ||
                      selectedGroupName == 'Collateral Manager' ||
                      selectedGroupName == 'Offtaker'
                  "
                  class="col-md-2 col-xs-6"
                >
                  <div class="form-group">
                    <label class="required-field">SubGroup Names</label>
                    <div class="line"></div>
                    <select
                      id="selectedSubGroupName"
                      class="form-control"
                      name="selectedSubGroupName"
                      v-model="selectedSubGroupName"
                      :class="{
                        'is-invalid': isSubmitted && $v.selectedSubGroupName.$error
                      }"
                    >
                      <option :value="null">--- New SubGroup ---</option>
                      <option
                        v-for="grp_name in SubGroupName"
                        :key="grp_name.sub_group_id"
                        :value="grp_name.sub_group_id"
                      >
                        {{ grp_name.subgroupname }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedSubGroupName.$error"
                      class="invalid-feedback"
                      style="color: red"
                    >
                      <span v-if="!$v.selectedSubGroupName.required"
                        >SubGroup Name is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-xs-6">
                  <div class="form-group">
                    <label class="required-field">Date Format</label>
                    <div class="line"></div>
                    <select
                      id="selectedDateFormat"
                      class="form-control"
                      name="selectedDateFormat"
                      v-model="selectedDateFormat"
                      :class="{
                        'is-invalid': isSubmitted && $v.selectedDateFormat.$error
                      }"
                    >
                      <option :value="null" disabled>--- Select ---</option>
                      <option
                        v-for="df in DateFormat"
                        :key="df.id"
                        :value="df.id"
                      >
                        {{ df.name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedDateFormat.$error"
                      class="invalid-feedback"
                      style="color: red"
                    >
                      <span v-if="!$v.selectedDateFormat.required">Date Format is required</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-xs-6">
                  <!-- <div v-if="message" class="alert alert-success" role="alert">{{message}}</div> -->
                  <error v-if="error" :error="error" />
                  <div class="form-group">
                    <label for="text" class="required-field">Password</label>
                    <input
                      type="password"
                      class="form-control"
                      id="password"
                      v-model="password"
                      name="password"
                      :class="{
                        'is-invalid': isSubmitted && $v.password.$error
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.password.$error"
                      class="invalid-feedback"
                      style="color: red"
                    >
                      <span v-if="!$v.password.required">Password field is required</span>
                      <span v-if="$v.password.required && !$v.password.minLength"
                        >Password should be at least 6 characters long</span
                      >
                      <span v-if="$v.password.required && !$v.password.maxLength"
                        >Password should not be more than 20 characters long</span
                      >
                      <span
                        v-if="
                          $v.password.required &&
                            $v.password.minLength &&
                            $v.password.maxLength &&
                            !$v.password.valid
                        "
                        >Password must have atleast One Uppercase, One Lowercase, One Number and One
                        Special Character</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-xs-12">
                  <div class="form-group">
                    <label for="text" class="lb required-field">Confirm Password</label>
                    <input
                      type="password"
                      class="form-control"
                      v-model="confirmpassword"
                      id="confirmpassword"
                      name="confirmpassword"
                      :class="{
                        'is-invalid': isSubmitted && $v.confirmpassword.$error
                      }"
                    />
                    <div
                      v-if="isSubmitted && $v.confirmpassword.$error"
                      class="invalid-feedback"
                      style="color: red"
                    >
                      <span v-if="!$v.confirmpassword.required"
                        >Confirm Password field is required</span
                      >
                      <span v-if="$v.confirmpassword.required && !$v.confirmpassword.sameAsPassword"
                        >Passwords should be matched</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 text-right mb-3">
                <button
                  style="margin-right: 6px"
                  type="button"
                  class="btn btn-info"
                  v-on:click="onSubmit()"
                >
                  Submit
                </button>
                <button
                  type="button"
                  class="btn btn-default"
                  @click="$router.go(-1)"
                  style="margin-right:6px cursor: pointer "
                >
                  Cancel
                </button>
              </div>
            </div>

            <!-- <div class="row" style="margin-top:20px;">
              <div class="col-md-12">
                <div class="alert alert-success alert-dismissible">
                  <a
                    href="#"
                    class="close"
                    data-dismiss="alert"
                    aria-label="close"
                    style="font-size: 42px;font-weight: normal;"
                  >×</a>
                  <ul class="b">
                    <li>Your password can't be too similar to your other personal information.</li>
                    <li>Your Password must contain at least 8 characters.</li>
                    <li>Your password can't be a commonly used password.</li>
                    <li>Your password can't be entirely numeric.</li>
                  </ul>
                </div>
              </div>
            </div>-->
          </div>
        </div>
        <!-- all other page content start -->

        <div id="footer">
          <div class="container-fluid">
            <div class="pull-right hidden-xs"><b>Version</b> 2.4.0</div>
            Copyright 2021
            <a href="https://www.lundyinvestors.com/" target="_blank">LUNDY INVESTORS</a>
          </div>
        </div>
      </div>
      <!-------------Main container start--------------->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function
import { required, minLength, sameAs, maxLength } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      //variables for Add User information
      UserName: null,
      email: null,
      GroupName: [],
      SubGroupName: [],
      DateFormat:[],
      clientDetails:[],
      selectedClientID:null,
      selectedDateFormat:null,
      selectedGroupName: null,
      selectedSubGroupName: null,
      isSubmitted: false,
      password: null,
      confirmpassword: null,
      msg: [],
      error: null
    };
  },
  watch: {
    email(value) {
      // binding this to the data value in the email input
      this.email = value;
      this.validateEmail(value);
    },
    companyWebsite(value) {
      // binding this to the data value in the website input
      this.companyWebsite = value;
      this.validateWebsite(value);
    }
  },
  computed: {
    //this is to computed disabled property of main Submit button to save information in database.
    // isDisabledOnSubmit: function() {
    //   if (
    //     this.UserName === null ||
    //     this.UserName === "" ||
    //     this.selectedGroupName === null ||
    //     this.selectedGroupName === "" ||
    //     this.password === null ||
    //     this.password === ""
    //   ) {
    //     //here we will check for all sections
    //     return true;
    //   }
    // } //isDisabledOnSubmit
  }, //main computed close
  validations: {
    password: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(20),
      valid: function(value) {
        const containsUppercase = /[A-Z]/.test(value);
        const containsLowercase = /[a-z]/.test(value);
        const containsNumber = /[0-9]/.test(value);
        const containsSpecial = /[#?!@$%^&*-]/.test(value);
        return containsUppercase && containsLowercase && containsNumber && containsSpecial;
      }
    },
    confirmpassword: {
      required,
      sameAsPassword: sameAs("password")
    },
    UserName: {
      required
    },
    email: {
      required
    },
    selectedGroupName: {
      required
    },
    selectedClientID: {
      required
    },
    selectedSubGroupName: {
      required: function() {
        if (
          (this.selectedGroupName === "Investor" || this.selectedGroupName == "Borrower") &&
          this.selectedSubGroupName === null
        ) {
          return false;
        } else {
          return true;
        }
      },
    },
    selectedDateFormat: {
      required
    },
  },
  methods: {
    validateEmail(value) {
      if (/^\w+([\.-]\w+)*@\w+([\.-]\w+)*(\.\w{2,3})+$/.test(value)) {
        this.msg["email"] = "";
      } else {
        this.msg["email"] = "Invalid Email Address";
      }
    },
    getSubGroupName() {
      console.log("GroupName>>" + this.selectedGroupName);
      axios
        .get("subgroupname/" + this.selectedGroupName, {
          params: { client_id: this.selectedClientID }
        })
        .then(res => {
          this.SubGroupName = res.data.subgroupnames;
          console.log("SUBGROUPDATA: ", res.data);
        })
        .catch(error => {
          console.error(error);
        });
    },

    getDateTypeFormat() {
      //this function is called to get list of banks
      const path = "date-format-type-master";
      axios
        .get(path)
        .then((res) => {
          console.log(res)
          console.log("DateFormat >>",res.data.date_format_type)
          this.DateFormat = res.data.date_format_type;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //this method will submit the facility form in backend.
    onSubmit() {
      console.log(this.loggedInClientId);
      console.log("*************");
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      //initialize the form data
      let formData = new FormData();
      //append company information to formdata
      console.log("selectedSubGroupName >>> ", this.selectedSubGroupName);
      formData.append("Username", this.UserName);
      formData.append("EmailID", this.email);
      formData.append("selectedGroupName", this.selectedGroupName);
      formData.append("selectedSubGroupName", this.selectedSubGroupName);
      formData.append("selectedDateFormat", this.selectedDateFormat);
      formData.append("Password", this.password);
      formData.append("ConfirmPassword", this.confirmpassword);
      formData.append("clientId", this.selectedClientID);
      const path = "/adduser/" + this.selectedClientID;
      Utils.start_loading();
      axios
        .post(path, formData, {
          headers: {
            enctype: "multipart/form-data"
          }
        })
        .then(response => {
          Utils.stop_loading();
          console.log("status: " + response.data.status);
          if (response.data.status == "success") {
            Utils.make_alert("success", "User added successfully! and Password Reset Link Sent");
            console.log("SUCCESS!!");
            this.$router.push("/permission");
          } else if (response.data.status == "user_already_exists") {
            Utils.make_alert("warning", "User already exists!");
            this.$router.push("/permission");
          } else if (response.data.status == "password_already_exists") {
            Utils.make_alert(
              "warning",
              "This password already exists, kindly enter a new password."
            );
          } else {
            Utils.make_alert("success", "FAILURE!!! Something went wrong!");
          }
        })
        .catch(error => {
          Utils.stop_loading();
          console.log("FAILURE!! In catch");
          console.error(error);
        });
    }, //onSubmit() close

    //this function is called to get list of documents master
    getGroupNames() {
      const path = "groupname";
      axios
        .get(path)
        .then(res => {
          let GroupName = res.data.groupnames;
          this.GroupName = GroupName.filter(group => group.group_name != "Super Admin");
        })
        .catch(error => {
          console.error(error);
        });
    }, //getGroupNames() close

    // //this function is called to get list of documents master
    getSubGroupNames() {
      const path = "subgroupname/" + this.selectedGroupName;
      axios
        .get(path)
        .then(res => {
          this.SubGroupName = res.data.subgroupnames;
        })
        .catch(error => {
          console.error(error);
        });
    } ,//getGroupNames() close

    getClientMaster() {
      //this function is called to get list of banks
      const path = "clientMaster";
      axios
        .get(path)
        .then((res) => {
          console.log(res)
          console.log("DateFormat >>",res.data.ClientMaster)
          this.clientDetails = res.data.clientDetails;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  created() {
    if(this.loggedInUserGroupId === 'Admin'){
      this.selectedClientID = this.$store.state.client_id;
    } else {
      this.selectedClientID = null;
    }
    this.getClientMaster();
    this.getGroupNames();
    this.getSubGroupNames();
    this.getDateTypeFormat();
  },
  computed: {
    loggedInUserGroupId() {
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInClientId() {
      var clientId = localStorage.getItem("client_id");
      console.log(clientId);
      return clientId;
    }
  }
};
</script>

<style scoped>
.whitepatch .form-group .form-control,
.input-group-addon {
  background-color: #f0f3f5 !important;
  border: none !important;
  height: 42px;
  color: #000 !important;
  box-shadow: none !important;
  margin: 0px;
  padding: 0px 14px !important;
}
.required-field::after {
  content: "*";
  color: red;
}
/* select.form-control:not([size]):not([multiple]) {
    height: calc(2.25rem + 2px);
}

.fc .form-control {
    border: none;
    padding: 4px 8px;
    height: 32px;
    font-size: 14px;
    background-color: #EEEEF0;
    border-bottom: 2px solid #DDDDE4;
    border-radius: 0px;
    box-shadow: none !important;
} */
</style>
