<template>
  <div>
    <div id="page-container">
      <!-------------Main container start--------------->

      <div id="content-wrap">
        <!-- all other page content start -->
        <admin-nav-bar></admin-nav-bar>

        <div
          class="container-fluid"
          id="headerbx"
          style="background-image: url(asset/images/tp_bg.png)"
        >
          <div class="container-fluid filtersearch">
            <div class="row">
              <div class="col-md-12">
                <div class="headingbg">
                  <h5>
                    <img
                      src="asset/images/summary.png"
                      class="img-responsive heading_image"
                      align="left"
                    />
                    Edit Group
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid" id="contentmaindiv">
          <div class="whitepatch dualbox">
            <div class="row">
              <div class="col-md-12">
                <div class="form-inline">
                  <div class="form-group">
                    <label for="text">Group Name:</label>
                    <input
                      type="text"
                      class="form-control"
                      id="text"
                      placeholder="Enter Group Name"
                      v-model="GroupName"
                    />
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="row">
              <div class="col-sm-12">
                <select
                  multiple="multiple"
                  name="duallistbox_permissions[]"
                  id="test"
                  v-model="selectGroupName"
                >
                  <option :value="null">--- Select ---</option>
                  <option
                    v-for="grp_name in GroupNameList"
                    :key="grp_name.id"
                  >{{ grp_name.group_name }}</option>
                </select>
              </div>
            </div>-->

            <div class="row">
              <div class="col-md-12 text-right mb-3">
                <button
                  style="margin-right:6px"
                  type="button"
                  class="btn btn-info"
                  v-on:click="onUpdate()"
                >
                  Submit
                </button>
                <button
                  type="button"
                  class="btn btn-default"
                  @click="$router.go(-1)"
                  style="margin-right:6px cursor: pointer"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- all other page content start -->

      <div id="footer">
        <div class="container-fluid">
          <div class="pull-right hidden-xs">
            <b>Version</b> 2.4.0
          </div>Copyright 2021
          <a href="https://www.lundyinvestors.com/" target="_blank">LUNDY INVESTORS</a>
        </div>
      </div>
    </div>
    <!-------------Main container start--------------->
  </div>
  <!-- all other page content start -->
</template>

<script>
import axios from "axios";
import { Utils } from "@/utils/CommonMethods.js"; //import commonMethods utils function

export default {
  data() {
    return {
      //variables for Add User information
      id: this.$route.params.id,
      GroupName: null,
      selectGroupName: [],
      GroupNameList: []
    };
  },
  computed: {}, //main computed close

  methods: {
    getData() {
      //this function is called on page load and created events. it will show details on fund with fundid equal to this.id
      const path = "addgroup";
      console.log("GROUPIDD");
      console.log(this.id);
      axios
        .get(path + "?id=" + this.id)
        .then(res => {
          var data;
          data = res.data.group[0];
          this.GroupName = data.group_name;
          if (res.data.status == "success") {
            console.log("get fund details is successful.");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch(error => {
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    }, //getFund close
    //this method will submit the facility form in backend.
    onUpdate() {
      var payload = {
        GroupID: this.id,
        GroupName: this.GroupName
      };
      const path = "addgroup";
      this.$confirm({
        auth: false,
        message: "Are you sure you want to update?",
        button: {
          no: "No",
          yes: "Yes"
        },

        callback: confirm => {
          if (confirm) {
            axios
              .put(path, payload)
              .then(response => {
                console.log("status: " + response.data.status);
                if (response.data.status == "success") {
                  Utils.make_alert("success", "Group updated successfully!");
                  console.log("SUCCESS!!");
                  this.$router.push("/permission");
                } else {
                  Utils.make_alert("success", "FAILURE!!! Something went wrong from server!");
                  console.log("Something went wrong from server!");
                  console.log("FAILURE!!");
                }
              })
              .catch(function() {
                console.log("FAILURE!!");
              });
          }
        }
      });
    }, //onSubmit() close

    //this function is called to get list of documents master
    getGroupNames() {
      const path = "groupname";
      axios
        .get(path)
        .then(res => {
          let GroupName = res.data.groupnames;
          this.GroupNameList = GroupName.filter(group => group.group_name != "Super Admin");
        })
        .catch(error => {
          console.error(error);
        });
    } //getGroupNames() close
  },
  created() {
    this.getData();
    this.getGroupNames();
  }
};
</script>

<style></style>
