<template>
  <div>
    <div id="page-container">
      <!-------------Main container start--------------->

      <div id="content-wrap">
        <!-- all other page content start -->
        <admin-nav-bar></admin-nav-bar>

        <div
          class="container-fluid"
          id="headerbx"
          style="background-image:url(asset/images/tp_bg.png);"
        >
          <div class="container-fluid filtersearch">
            <div class="row">
              <div class="col-md-12">
                <div class="headingbg">
                  <h5>
                    <img
                      src="asset/images/summary.png"
                      class="img-responsive heading_image"
                      align="left"
                    />
                    Benchmark Master Table
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid" id="contentmaindiv">
          <div class="whitepatch dualbox">
            <div class="row">
              <div class="col-md-12">
                <div class="addusr">
                  <a type="button" class="btn btn-succes back-btn" @click="$router.go(-1)">
                    <i class="icofont-caret-left text-dark"></i>
                    <span>Back</span>
                  </a>
                </div>
              </div>
            </div>

            <div class="fr_b">
              <div class="row">
                <div class="col-md-2 col-xs-6">
                  <div class="form-group">
                    <label for="text" class="required-field">Benchmark Name</label>
                    <input
                      v-model="benchmarkName"
                      type="text"
                      class="form-control"
                      id="benchmarkName"
                      name="benchmarkName"
                      :class="{ 'is-invalid': isSubmitted && $v.benchmarkName.$error }"
                      placeholder="Type..."
                    />
                    <div
                      v-if="isSubmitted && $v.benchmarkName.$error"
                      class="invalid-feedback"
                      style="color: red;"
                    >
                      <span v-if="!$v.benchmarkName.required">Benchmark Name is required</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-xs-6">
                  <div class="form-group">
                    <label for="text">Benchmark Description</label>
                    <input
                      v-model="benchmarkDesc"
                      type="text"
                      class="form-control"
                      id="benchmarkdescription"
                      placeholder="Type..."
                    />
                  </div>
                </div>

                <!-- <div class="col-md-2 col-xs-6">
                  <div class="form-group">
                    <label>Created by</label>
                    <div class="line"></div>
                    {{ currentUser }}
                  </div>
                </div>-->
              </div>
            </div>

            <div v-if="update == true" class="row">
              <div class="col-md-12">
                <button
                  style="margin-right:6px"
                  type="button"
                  class="btn btn-info"
                  v-on:click="onUpdate()"
                >
                  Update
                </button>
                <button
                  type="button"
                  class="btn btn-default"
                  @click="onCancel()"
                  style="margin-right:6px cursor: pointer"
                >
                  Cancel
                </button>
              </div>
            </div>
            <div v-else class="row">
              <div class="col-md-12">
                <button
                  style="margin-right:6px"
                  type="button"
                  class="btn btn-info"
                  v-on:click="onSubmit()"
                >
                  Submit
                </button>
                <!-- <button
                  type="button"
                  class="btn btn-default"
                  @click="$router.go(-1)"
                  style="margin-right:6px cursor: pointer"
                >
                  Cancel
                </button>-->
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid" id="contentmaindiv">
          <div class="whitepatch">
            <div class="table-responsive">
              <table id="myTable1" class="table table-hover myTable1">
                <thead>
                  <tr>
                    <!-- <th class="c">Sr.no.</th> -->
                    <th>Benchmark Name</th>
                    <th>Benchmark Description</th>
                    <!-- <th>Group Name</th> -->
                    <th class="c"></th>
                    <th class="c"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(usr, index) in UserList" :key="usr.id">
                    <!-- <td class="c">{{ usr.id }}</td> -->
                    <td
                      v-if="
                        usr.benchmark_name !== null &&
                          usr.benchmark_name !== 'None' &&
                          usr.benchmark_name !== ''
                      "
                    >
                      {{ usr.benchmark_name }}
                    </td>
                    <td v-else>-</td>
                    <td
                      v-if="
                        usr.benchmark_desc !== null &&
                          usr.benchmark_desc !== 'None' &&
                          usr.benchmark_desc !== ''
                      "
                    >
                      {{ usr.benchmark_desc }}
                    </td>
                    <td v-else>-</td>
                    <td class="c">
                      <a v-on:click="getData(usr.id)" :style="{ cursor: 'pointer' }">
                        <i class="fa fa-pencil gr" aria-hidden="true"></i>
                      </a>
                    </td>
                    <td class="c">
                      <a v-on:click="onDelete(usr.id, index)" :style="{ cursor: 'pointer' }">
                        <i class="fa fa-trash rd" aria-hidden="true"></i>
                      </a>
                    </td>
                  </tr>
                  <!------------------------>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- all other page content start -->

        <div id="footer">
          <div class="container-fluid">
            <div class="pull-right hidden-xs"><b>Version</b> 2.4.0</div>
            Copyright 2021
            <a href="https://www.lundyinvestors.com/" target="_blank">LUNDY INVESTORS</a>
          </div>
        </div>
      </div>
      <!-------------Main container start--------------->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Utils } from "../../../utils/CommonMethods.js";
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      //variables for Add User information
      id: null,
      path: "admin-benchmark",
      UserList: [],
      currentUser: this.$store.getters.getuserFullName || [],
      benchmarkName: null,
      benchmarkDesc: null,
      update: false,
      error: "",
      isSubmitted: false
    };
  },
  validations: {
    benchmarkName: {
      required
    }
  },
  computed: {
    //this is to computed disabled property of main Submit button to save information in database.
    // isDisabledOnSubmit: function() {
    //   if (this.benchmarkName === null) {
    //     //here we will check for all sections
    //     return true;
    //   }
    // } //isDisabledOnSubmit
  }, //main computed close
  methods: {
    onSubmit() {
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      var payload = {
        BenchMarkName: this.benchmarkName,
        BenchMarkDesc: this.benchmarkDesc,
        createdBy: this.$store.getters.getuserId
      };
      axios
        .post(this.path, payload)
        .then(response => {
          console.log("status: " + response.data.status);
          if (response.data.status == "success") {
            Utils.make_alert("success", "Benchmark Master added successfully!");
            console.log("SUCCESS!!");
            setTimeout(() => this.$router.go(), 1500);
          } else {
            Utils.make_alert("success", "FAILURE!!! Something went wrong from server!");
            console.log("Something went wrong from server!");
            console.log("FAILURE!!");
          }
        })
        .catch(function() {
          console.log("FAILURE!!");
        });
    }, //onSubmit() close
    getData(id) {
      this.update = true;
      this.id = id;
      //this function is called on page load and created events. it will show details on benchmark with benchmarkid equal to this.id
      axios
        .get(this.path + "?id=" + this.id)
        .then(res => {
          var data;
          data = res.data.benchmarks[0];
          this.benchmarkName = data.benchmark_name;
          this.benchmarkDesc = data.benchmark_desc;
          this.createdBy = data.created_by;
          this.updatedBy = data.updated_by;
          if (res.data.status == "success") {
            console.log("get benchmark details is successful.");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch(error => {
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    }, //getbenchmark close
    onUpdate() {
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      var payload = {
        id: this.id,
        BenchMarkName: this.benchmarkName,
        BenchMarkDesc: this.benchmarkDesc,
        updatedBy: this.$store.getters.getuserId
      };
      this.$confirm({
        auth: false,
        message: "Are you sure you want to update?",
        button: {
          no: "No",
          yes: "Yes"
        },

        callback: confirm => {
          if (confirm) {
            axios
              .put(this.path, payload)
              .then(response => {
                console.log("status: " + response.data.status);
                if (response.data.status == "success") {
                  Utils.make_alert("success", "Benchmark Master updated successfully!");
                  console.log("SUCCESS!!");
                  this.benchmarkName = null;
                  this.benchmarkDesc = null;
                  this.update = false;
                  setTimeout(() => this.$router.go(), 1500);
                } else {
                  Utils.make_alert("success", "FAILURE!!! Something went wrong from server!");
                  console.log("Something went wrong from server!");
                  console.log("FAILURE!!");
                }
              })
              .catch(function() {
                console.log("FAILURE!!");
              });
          }
        }
      });
    }, //onSubmit() close
    //this method will submit the facility form in backend.
    getTableData() {
      //this function is called on page load and created events. it will show details on benchmark with benchmarkid equal to this.id
      axios
        .get(this.path)
        .then(res => {
          this.UserList = res.data.benchmarks;
          if (res.data.status == "success") {
            console.log("get benchmark details is successful.");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch(error => {
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    }, //getbenchmark close
    onDelete(id, index) {
      this.$confirm({
        auth: false,
        message: "Are you sure you want to delete?",
        button: {
          no: "No",
          yes: "Yes"
        },

        callback: confirm => {
          if (confirm) {
            axios
              .delete(this.path, {
                data: {
                  id: id
                }
              })
              .then(res => {
                if (res.data.status == "success") {
                  this.UserList.splice(index, 1);
                  Utils.make_alert("success", "Benchmark Deleted successfully.");
                  console.log("Benchmark Deleted successfully.");
                  setTimeout(() => this.$router.go(), 1500);
                } else {
                  console.log("Something went wrong from server!");
                }
              })
              .catch(error => {
                console.error("error: " + error);
                console.log("Something went wrong!");
              });
          }
        }
      });
    }, //getbenchmark close
    onCancel() {
      this.update = false;
      this.benchmarkName = null;
      this.benchmarkDesc = null;
    }
  },
  created() {
    this.getTableData();
  }
};
</script>

<style>
.required-field::after {
  content: "*";
  color: red;
}
.back-btn span {
  text-transform: uppercase;
  font-size: 15px !important;
  color: #a6a6a6;
  position: relative;
  top: -3px;
  left: -8px;
}
.back-btn i {
  font-size: 24px;
  color: #343a40 !important;
  padding-right: 5px;
}
</style>
