<template>
  <div>
    <div id="page-container">
      <!-------------Main container start--------------->
      <div id="content-wrap">
        <!-- all other page content start -->
        <admin-nav-bar></admin-nav-bar>

        <div
          class="container-fluid"
          id="headerbx"
          style="background-image: url(asset/images/tp_bg.png)"
        >
          <div class="container-fluid filtersearch">
            <div class="row">
              <div class="col-md-12">
                <div class="headingbg">
                  <h5>
                    <img
                      src="asset/images/summary.png"
                      class="img-responsive heading_image"
                      align="left"
                    />Edit User
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid" id="contentmaindiv">
          <div class="whitepatch dualbox">
            <div class="fr_b">
              <div class="row">
                <div class="col-md-2 col-xs-6">
                  <div class="form-group">
                    <label for="text" class="required-field">User Name</label>
                    <input
                      type="text"
                      class="form-control"
                      id="UserName"
                      name="UserName"
                      :class="{
                        'is-invalid': isSubmitted && $v.UserName.$error
                      }"
                      v-model="UserName"
                    />
                    <div
                      v-if="isSubmitted && $v.UserName.$error"
                      class="invalid-feedback"
                      style="color: red"
                    >
                      <span v-if="!$v.UserName.required">User Name is required</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-xs-6">
                  <div class="form-group">
                    <label for="email" class="required-field">Email Address</label>
                    <input
                      type="email"
                      class="form-control"
                      id="email"
                      v-model="email"
                      name="email"
                      :class="{ 'is-invalid': isSubmitted && $v.email.$error }"
                    />
                    <small>
                      <span style="color: red; cursor: pointer" v-if="msg.email">{{
                        msg.email
                      }}</span>
                    </small>
                    <div
                      v-if="isSubmitted && $v.email.$error"
                      class="invalid-feedback"
                      style="color: red"
                    >
                      <span v-if="!$v.email.required">Email Address is required</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-xs-6">
                  <div class="form-group">
                    <label class="required-field">Group Name</label>
                    <div class="line"></div>
                    <select
                      id="userGroupName"
                      class="form-control"
                      name="userGroupName"
                      v-model="userGroupName"
                      @change="getSubGroupName"
                      :class="{
                        'is-invalid': isSubmitted && $v.userGroupName.$error
                      }"
                    >
                      <option :value="null">--- Select ---</option>
                      <option
                        v-for="grp_name in GroupName"
                        :key="grp_name.id"
                        :value="grp_name.group_name"
                      >
                        {{ grp_name.group_name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.userGroupName.$error"
                      class="invalid-feedback"
                      style="color: red"
                    >
                      <span v-if="!$v.userGroupName.required">Group Name is required</span>
                    </div>
                  </div>
                </div>

                <div
                  v-if="
                    userGroupName == 'Investor' ||
                      userGroupName == 'Borrower' ||
                      userGroupName == 'Collateral Manager' ||
                      userGroupName == 'Offtaker'
                  "
                  class="col-md-2 col-xs-6"
                >
                  <div class="form-group">
                    <label class="required-field">SubGroup Names</label>
                    <div class="line"></div>
                    <select
                      id="selectedSubGroupName"
                      class="form-control"
                      name="selectedSubGroupName"
                      v-model="selectedSubGroupName"
                      :class="{
                        'is-invalid': isSubmitted && $v.selectedSubGroupName.$error
                      }"
                    >
                      <option :value="null">--- New SubGroup ---</option>
                      <option
                        v-for="grp_name in SubGroupName"
                        :key="grp_name.sub_group_id"
                        :value="grp_name.sub_group_id"
                      >
                        {{ grp_name.subgroupname }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedSubGroupName.$error"
                      class="invalid-feedback"
                      style="color: red"
                    >
                      <span v-if="!$v.selectedSubGroupName.required"
                        >SubGroup Name is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-xs-6">
                  <div class="form-group">
                    <label class="required-field">Date Format</label>
                    <div class="line"></div>
                    <select
                      id="selectedDateFormat"
                      class="form-control"
                      name="selectedDateFormat"
                      v-model="selectedDateFormat"
                      :class="{
                        'is-invalid': isSubmitted && $v.selectedDateFormat.$error
                      }"
                    >
                      <option :value="null" disabled>--- Select ---</option>
                      <option
                        v-for="df in DateFormat"
                        :key="df.id"
                        :value="df.id"
                      >
                        {{ df.name }}
                      </option>
                    </select>
                    <div
                      v-if="isSubmitted && $v.selectedDateFormat.$error"
                      class="invalid-feedback"
                      style="color: red"
                    >
                      <span v-if="!$v.selectedDateFormat.required">Date Format is required</span>
                    </div>
                  </div>
                </div>

              </div>
            </div>


            <div class="row">
              <div class="col-md-12 text-right mb-3">
                <button
                  style="margin-right: 6px"
                  type="button"
                  class="btn btn-info"
                  v-on:click="onUpdate()"
                >
                  Submit
                </button>
                <button
                  type="button"
                  class="btn btn-default"
                  @click="$router.go(-1)"
                  style="margin-right:6px cursor: pointer"
                >
                  Cancel
                </button>
              </div>
            </div>

            <!-- <div class="row" style="margin-top:20px;">
              <div class="col-md-12">
                <div class="alert alert-success alert-dismissible">
                  <a
                    href="#"
                    class="close"
                    data-dismiss="alert"
                    aria-label="close"
                    style="font-size: 42px;font-weight: normal;"
                  >×</a>
                  <ul class="b">
                    <li>Your password can't be too similar to your other personal information.</li>
                    <li>Your Password must contain at least 8 characters.</li>
                    <li>Your password can't be a commonly used password.</li>
                    <li>Your password can't be entirely numeric.</li>
                  </ul>
                </div>
              </div>
            </div>-->
          </div>
        </div>
        <!-- all other page content start -->

        <div id="footer">
          <div class="container-fluid">
            <div class="pull-right hidden-xs"><b>Version</b> 2.4.0</div>
            Copyright 2021
            <a href="https://www.lundyinvestors.com/" target="_blank">LUNDY INVESTORS</a>
          </div>
        </div>
      </div>
      <!-------------Main container start--------------->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Utils } from "../../utils/CommonMethods.js"; //import commonMethods utils function

import { required, minLength, sameAs, maxLength } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      //variables for Add User information
      id: this.$route.params.id,
      UserName: null,
      email: null,
      GroupName: [],
      SubGroupName: [],
      DateFormat:[],
      selectedDateFormat:null,
      userGroupName: null,
      selectedSubGroupName: null,
      selectedClientID: null,
      isSubmitted: false,
      msg: [],
      error: null
    };
  },
  watch: {
    email(value) {
      // binding this to the data value in the email input
      this.email = value;
      this.validateEmail(value);
    }
  },
  computed: {}, //main computed close
  validations: {
    UserName: {
      required
    },
    email: {
      required
    },
    userGroupName: {
      required
    },
    selectedSubGroupName: {
      required: function() {
        if (
          (this.userGroupName == "Investor" || this.userGroupName == "Borrower") &&
          this.selectedSubGroupName === null
        ) {
          return false;
        } else {
          return true;
        }
      }
    },
    selectedDateFormat: {
      required
    },
  },
  methods: {
    validateEmail(value) {
      if (/^\w+([\.-]\w+)*@\w+([\.-]\w+)*(\.\w{2,3})+$/.test(value)) {
        this.msg["email"] = "";
      } else {
        this.msg["email"] = "Invalid Email Address";
      }
    },
    getSubGroupName() {
      console.log("GroupName>>" + this.userGroupName);
      axios
        .get("subgroupname/" + this.userGroupName, {
          params: { client_id: this.selectedClientID }
        })
        .then(res => {
          this.SubGroupName = res.data.subgroupnames;
          this.SubGroupName.filter(sub => {
            if (sub.subgroupname === this.selectedSubGroupName)
              this.selectedSubGroupName = sub.sub_group_id;
          });
        })
        .catch(error => {
          console.error(error);
        });
    },
    getDateTypeFormat() {
      //this function is called to get list of banks
      const path = "date-format-type-master";
      axios
        .get(path)
        .then((res) => {
          console.log(res)
          console.log("DateFormat >>",res.data.date_format_type)
          this.DateFormat = res.data.date_format_type;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async getData() {
      //this function is called on page load and created events. it will show details on fund with fundid equal to this.id
      const path = "/adduser/" + this.selectedClientID;
      await axios
        .get(path, { params: { id: this.id} })
        .then(res => {
          var data;

          data = res.data.user[0];
          console.log("0 data", data);
          this.UserName = data.username;
          this.email = data.email;
          this.userGroupName = data.group_name;
          this.selectedSubGroupName = data.sub_group_id;
          this.selectedDateFormat = data.date_format_type_master_id;
          this.selectedClientID = data.client_id;
          console.log("SubGroup: ", this.selectedSubGroupName);
          if (res.data.status == "success") {
            console.log("get fund details is successful.");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch(error => {
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
      this.getSubGroupName();
    }, //getFund close
    //this method will submit the facility form in backend.
    onUpdate() {
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      var payload = {
        userID: this.id,
        groupID: this.userGroupName,
        subGroupId: this.selectedSubGroupName,
        selectedDateFormat: this.selectedDateFormat
      };
      const path = "adduser/" + this.selectedClientID;
      this.$confirm({
        auth: false,
        message: "Are you sure you want to update?",
        button: {
          no: "No",
          yes: "Yes"
        },

        callback: confirm => {
          if (confirm) {
            axios
              .put(path, payload)
              .then(response => {
                console.log("status: " + response.data.status);
                if (response.data.status == "success") {
                  Utils.make_alert("success", "User detail updated successfully!");
                  console.log("SUCCESS!!");
                  this.$router.push("/permission");
                } else {
                  Utils.make_alert("success", "FAILURE!!! Something went wrong from server!");
                  console.log("Something went wrong from server!");
                  console.log("FAILURE!!");
                }
              })
              .catch(function() {
                console.log("FAILURE!!");
              });
          }
        }
      });
    }, //onSubmit() close

    //this function is called to get list of documents master
    getGroupNames() {
      const path = "groupname";
      axios
        .get(path)
        .then(res => {
          let GroupName = res.data.groupnames;
          this.GroupName = GroupName.filter(group => group.group_name != "Super Admin");
        })
        .catch(error => {
          console.error(error);
        });
    } //getGroupNames() close
  },
  created() {
    console.log(this.$route.params);
    this.UserName = this.$route.params.username;
    this.selectedClientID = this.$route.params.client_id;
    this.email = this.$route.params.email;
    this.userGroupName = this.$route.params.group;
    this.selectedSubGroupName = this.$route.params.subgroup;
    this.selectedDateFormat = this.$route.params.date_format_type_master_id;
    this.getDateTypeFormat();
    this.getData();
    this.getGroupNames();
    this.getSubGroupName();
  }
};
</script>

<style scoped>
.whitepatch .form-group .form-control,
.input-group-addon {
  background-color: #f0f3f5 !important;
  border: none !important;
  height: 42px;
  color: #000 !important;
  box-shadow: none !important;
  margin: 0px;
  padding: 0px 14px !important;
}
.required-field::after {
  content: "*";
  color: red;
}
</style>
