<template>
  <div>
    <!-------------Main container start--------------->

    <div id="content-wrap">
      <!-- all other page content start -->

      <div class="container-fluid" id="contentmaindiv">
        <div class="whitepatch">
          <div class="table-responsive">
            <table id="myTable1" class="table table-hover myTable1">
              <thead>
                <tr>
                
                  <th>User Name</th>
                  <th>Email Address</th>
                  <th>Group Name</th>
                  <th class="c"></th>
                  <th class="c"></th>
                </tr>
              </thead>
              <tbody>
                <div v-for="(row, index) in MasterList" :key="index">
                  <div class="row">
                    <tr v-for="(value,key) in row" :key="key">
                      <td class="c">{{ value }}</td>
                      
                    </tr>
                  </div>
                </div>
                <!------------------------>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- all other page content start -->
    </div>
    <!-------------Main container start--------------->
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["table_name"],
  data() {
    return {
      //variables for Add User information
      MasterList: [],
    };
  },
  methods: {
    //this method will submit the facility form in backend.
    // getTableData() {
    //   //this function is called on page load and created events. it will show details on fund with fundid equal to this.id
    //   const path = "permission/";
    //   axios
    //     .get(path)
    //     .then((res) => {
    //       console.log(res.data);
    //       this.UserList = res.data.permission.UserList;
    //       if (res.data.status == "success") {
    //         console.log("get fund details is successful.");
    //       } else {
    //         console.log("Something went wrong from server!");
    //       }
    //     })
    //     .catch((error) => {
    //       console.error("error: " + error);
    //       console.log("Something went wrong!");
    //     });
    // }, //getFund close

    getTableData() {
      //this function is called on page load and created events. it will show details on fund with fundid equal to this.id
      const path = this.table_name;
      axios
        .get(path)
        .then((res) => {
          console.log(res.data);
          this.MasterList = res.data;
          if (res.data.status == "success") {
            console.log("GOGLOGO");
            console.log(this.MasterList);
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch((error) => {
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    }, //getFund close
  },
  created() {
    this.getTableData();
  },
};
</script>

<style>
</style>