<template>
  <div class="container-fluid">
    <div class="row px-0">
      <div class="col-lg-5 col-md-5  px-0">
        <div id="side_view" class="blue_portion">
          <div style="padding-left: 40px !important;" class="tx">
            <h3 style="font-size: 80px !important; line-height: 70px !important;">LUNDY <br/> INVESTORS</h3>
            <h4>Tailored Solution for Wealth Creation</h4>
          </div>
        </div>
      </div>

      <div class="col-lg-7 col-md-7  px-0">
        <div id="grey" class="h-100v">
          <div class="login_mid-Portion ">
            <div class="brand-text">
              <center>
                <img src="/asset/images/AdminLTELogo.png" class="img-fluid login_logo" />
              </center>
            </div>
            <div class="brand-text">
              <h2>You Are Not Authorised</h2>
            </div>
          </div>

          <p class="bt_text1" style="padding-left: 16px; padding-right: 16px;">
            © 2022, Design & Development by
            <a href="https://www.decimalpointanalytics.com/">Decimal Point Analytics Pvt Ltd.</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Unauthorised",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped></style>
