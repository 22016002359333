<template>
  <div>
    <div id="page-container">
      <!-------------Main container start--------------->

      <div id="content-wrap">
        <!-- all other page content start -->

        <admin-nav-bar></admin-nav-bar>

        <div
          class="container-fluid"
          id="headerbx"
          style="background-image: url(asset/images/tp_bg.png)"
        >
          <div class="container-fluid filtersearch">
            <div class="row">
              <div class="col-md-12">
                <div class="headingbg">
                  <h5>
                    <img
                      src="asset/images/summary.png"
                      class="img-responsive heading_image"
                      align="left"
                    />
                    Group View
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid" id="contentmaindiv">
          <div class="whitepatch dualbox">
            <div class="row">
              <div class="col-md-12">
                <div class="form-inline">
                  <div class="form-group">
                    <label for="text">Group Name:</label>
                    <input
                      type="text"
                      class="form-control"
                      id="text"
                      placeholder="Investor"
                      v-model="GroupName"
                      readonly
                    />
                  </div>
                  <!-- <button type="submit" class="btn btn-info">
                    Edit Permissions
                  </button>-->
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <select
                  multiple="multiple"
                  name="duallistbox_permissions[]"
                  id="test"
                  v-model="selectGroupName"
                >
                  <option :value="null">--- Select ---</option>
                  <option v-for="grp_name in GroupNameList" :key="grp_name.id" disabled>{{
                    grp_name.group_name
                  }}</option>
                  <!-- <option value="option1" selected="selected" disabled>Option 1</option>
                  <option value="option2" selected="selected" disabled>Option 2</option>
                  <option value="option3" selected="selected" disabled>Option 3</option>
                  <option value="option4" selected="selected" disabled>Option 4</option>
                  <option value="option5" selected="selected" disabled>Option 5</option>
                  <option value="option6" selected="selected" disabled>Option 6</option>
                  <option value="option7" selected="selected" disabled>Option 7</option>
                  <option value="option8" selected="selected" disabled>Option 8</option>
                  <option value="option9" selected="selected" disabled>Option 9</option>
                  <option value="option0" selected="selected" disabled>Option 10</option>-->
                </select>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <a
                  type="button"
                  class="btn btn-default back-btn"
                  @click="$router.go(-1)"
                  style="margin-right:6px cursor: pointer"
                >
                  <i class="icofont-caret-left text-dark"></i>
                  <span>Back</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- all other page content start -->

        <div id="footer">
          <div class="container-fluid">
            <div class="pull-right hidden-xs">
              <b>Version</b> 2.4.0
            </div>Copyright 2021
            <a href="https://www.lundyinvestors.com/" target="_blank">LUNDY INVESTORS</a>
          </div>
        </div>
      </div>
      <!-------------Main container start--------------->
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      //variables for Add User information
      id: this.$route.params.id,
      GroupName: null,
      GroupNameList: [],
      selectGroupName: []
    };
  },
  methods: {
    getData() {
      //this function is called on page load and created events. it will show details on fund with fundid equal to this.id
      const path = "addgroup";
      axios
        .get(path + "?id=" + this.id)
        .then(res => {
          var data;
          data = res.data.group[0];
          this.GroupName = data.group_name;
          if (res.data.status == "success") {
            console.log("get fund details is successful.");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch(error => {
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    }, //getFund close
    //this function is called to get list of documents master
    getGroupNames() {
      const path = "groupname";
      axios
        .get(path)
        .then(res => {
          let GroupName = res.data.groupnames;
          this.GroupName = GroupName.filter(group => group.group_name != "Super Admin");
          let GroupNameList = res.data.groupnames;
          this.GroupNameList = GroupNameList.filter(group => group.group_name != "Super Admin");
        })
        .catch(error => {
          console.error(error);
        });
    } //getGroupNames() close
  },
  created() {
    this.getData();
    this.getGroupNames();
  }
};
</script>

<style>
.required-field::after {
  content: "*";
  color: red;
}
.back-btn span {
  text-transform: uppercase;
  font-size: 15px !important;
  color: #a6a6a6;
  position: relative;
  top: -3px;
  left: -8px;
}
.back-btn i {
  font-size: 24px;
  color: #343a40 !important;
  padding-right: 5px;
}
</style>
