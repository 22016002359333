<template>
  <div>
    <!-- <router-link to="/login"></router-link> -->
  </div>
</template>

<script>
export default {
  created() {
    // this.$store.dispatch("destroyAdminToken");
    this.$store.dispatch("destroyAdminToken");
    this.$router.push({ name: "AdminLogin" });
  }
};
</script>

<style>
</style>