<template>
  <div class="wrapper">
    <div id="page-container">
      <!-------------Main container start--------------->

      <div id="content-wrap">
        <!-- all other page content start -->
        <admin-nav-bar></admin-nav-bar>

        <div
          class="container-fluid"
          id="headerbx"
          style="background-image: url(asset/images/tp_bg.png)"
        >
          <div class="container-fluid filtersearch">
            <div class="row">
              <div class="col-md-12">
                <div class="headingbg">
                  <h5>
                    <img
                      src="asset/images/summary.png"
                      class="img-responsive heading_image"
                      align="left"
                    />
                    Authentication & Authorization
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid whitepatch" id="contentmaindiv">
          <!-- <div class="whitepatch"> -->
          <ul class="nav nav-tabs">
            <li class="active">
              <a data-toggle="tab" href="#group">
                <i class="fa fa-users" aria-hidden="true"></i> Group
              </a>
            </li>
            <li v-if="loggedInUserGroupId === 'Super Admin'">
              <a data-toggle="tab" href="#client">
                <i class="fa fa-user" aria-hidden="true"></i>
                Clients
              </a>
            </li>
            <li>
              <a data-toggle="tab" href="#investor">
                <i class="fa fa-user" aria-hidden="true"></i>
                Users
              </a>
            </li>
            <li v-if="loggedInUserGroupId === 'Super Admin'">
              <a data-toggle="tab" href="#tables">
                <i class="fa fa-table" aria-hidden="true"></i>
                Tables
              </a>
            </li>
            <li v-if="loggedInUserGroupId === 'Admin'">
              <a data-toggle="tab" href="#audit">
                <i class="fa fa-list-ul" aria-hidden="true"></i>
                Audit Trail
              </a>
            </li>
          </ul>

          <div class="tab-content">
            <!-- <div class="whitepatch"> -->
            <div id="group" class="tab-pane fade in active">
              <div v-for="(grp, index) in GroupList" :key="index">
                <div class="col-lg-2 col-md-4 col-sm-6 col-xs-6">
                  <div class="grid">
                    <figure class="dot" @click="onGroupChange(grp.group_name)">
                      <center>
                        <img
                          v-if="grp.id == 1"
                          src="asset/logo/investor.png"
                          class="img-responsive m_ic"
                        />
                        <img
                          v-else-if="grp.id == 2"
                          src="asset/logo/borrower.png"
                          class="img-responsive m_ic"
                        />
                        <img
                          v-else-if="grp.id == 5"
                          src="asset/logo/analyst.png"
                          class="img-responsive m_ic"
                        />
                        <img
                          v-else-if="grp.id == 3"
                          src="asset/logo/manager.png"
                          class="img-responsive m_ic"
                        />
                        <img
                          v-else-if="grp.id == 4"
                          src="asset/logo/offtaker.png"
                          class="img-responsive m_ic"
                        />
                        <img
                          v-else-if="grp.id == 6"
                          src="asset/logo/manager.png"
                          class="img-responsive m_ic"
                        />
                      </center>
                      <h3>{{ grp.group_name }}</h3>
                      <figcaption>
                        <!-- <button
                          type="button"
                          class="btn-sm btn btn-info"
                          data-toggle="tooltip"
                          data-placement="top"
                          @click="
                                $router.push({
                                  name: 'UpdateGroup',
                                  params: { id: grp.id },
                                })
                              "
                          style="cursor: pointer"
                          title="Edit!"
                        >
                          <i class="fa fa-pencil" aria-hidden="true"></i>
                        </button> -->
                        <!-- <button
                          type="button"
                          class="btn-sm btn btn-danger"
                          data-toggle="tooltip"
                          data-placement="top"
                          v-on:click="deleteGroup(grp.id, index)"
                          :style="{ cursor: 'pointer' }"
                          title="Delete!"
                        >
                          <i class="fa fa-trash" aria-hidden="true"></i>
                        </button> -->
                      </figcaption>
                    </figure>
                  </div>
                </div>
              </div>
              <table id="myTable2" class="table table-hover myTable2">
                <thead>
                  <tr>
                    <th>User Name</th>
                    <th>Email Address</th>
                    <template v-if="loggedInUserGroupId === 'Super Admin'">
                      <th>Client Name</th>
                    </template>
                    <th>Group Name</th>
                    <th>Sub Group</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!selectedGroup.length">
                    No data available
                  </tr>
                  <tr v-for="usr in selectedGroup" :key="usr.id">
                    <td v-if="usr.username !== null">{{ usr.username }}</td>
                    <td v-else>-</td>
                    <td v-if="usr.email !== null">{{ usr.email }}</td>
                    <td v-else>-</td>
                    <template v-if="loggedInUserGroupId === 'Super Admin'">
                      <td v-if="usr.client_name !== null">{{ usr.client_name }}</td>
                      <td v-else>-</td>
                    </template>
                    <td v-if="usr.group_name !== null">
                      {{ usr.group_name }}
                    </td>
                    <td v-else>-</td>
                    <td v-if="usr.subgroup_name !== null">
                      {{ usr.subgroup_name }}
                    </td>
                    <td v-else>-</td>
                  </tr>
                  <!------------------------>
                </tbody>
              </table>
            </div>

            <div v-if="loggedInUserGroupId === 'Super Admin'" id="client" class="tab-pane fade">
              <div class="row">
                <div class="col-md-12">
                  <div class="addusr">
                    <a type="button" class="btn btn-success" @click="$router.push('/add_client')">
                      <i class="fa fa-plus" aria-hidden="true"></i> Add Client
                    </a>
                  </div>
                </div>
              </div>

              <div class="table-responsive">
                <table id="myTable3" class="table table-hover myTable1">
                  <thead>
                    <tr>
                      <th>Client Name</th>
                      <th>Email Address</th>
                      <th>Incorporation Date</th>
                      <th>Website</th>
                      <th>Address</th>
                      <th>Registration Date</th>
                      <th class="c"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(usr, index) in ClientList" :key="usr.client_id">
                      <td v-if="usr.client_name !== null">{{ usr.client_name }}</td>
                      <td v-else>-</td>
                      <td v-if="usr.email !== null">{{ usr.email }}</td>
                      <td v-else>-</td>
                      <td v-if="usr.incorporation_date !== null">{{ usr.incorporation_date }}</td>
                      <td v-else>-</td>
                      <td v-if="usr.website !== null">{{ usr.website }}</td>
                      <td v-else>-</td>
                      <td v-if="usr.address !== null">{{ usr.address }}</td>
                      <td v-else>-</td>
                      <td v-if="usr.registration_date !== null">{{ usr.registration_date }}</td>
                      <td v-else>-</td>
                      <td class="c">
                        <a v-on:click="deleteClient(usr.client_id, index)" :style="{ cursor: 'pointer' }">
                          <i class="fa fa-trash rd" aria-hidden="true"></i>
                        </a>
                      </td>
                    </tr>
                    <!------------------------>
                  </tbody>
                </table>
              </div>
            </div>

            <div id="investor" class="tab-pane fade">
              <div class="row">
                <div class="col-md-12">
                  <div class="addusr">
                    <a type="button" class="btn btn-success" @click="$router.push('/add_user')">
                      <i class="fa fa-plus" aria-hidden="true"></i> Add User
                    </a>
                  </div>
                </div>
              </div>

              <div class="table-responsive">
                <table id="myTable1" class="table table-hover myTable1">
                  <thead>
                    <tr>
                      <th>User Name</th>
                      <th>Email Address</th>
                      <template v-if="loggedInUserGroupId === 'Super Admin'">
                        <th>Client Name</th>
                      </template>
                      <th>Group Name</th>
                      <th>Sub Group</th>
                      <th class="c"></th>
                      <th class="c"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(usr, index) in UserList" :key="usr.id">
                      <td v-if="usr.username !== null">{{ usr.username }}</td>
                      <td v-else>-</td>
                      <td v-if="usr.email !== null">{{ usr.email }}</td>
                      <td v-else>-</td>
                      <template v-if="loggedInUserGroupId === 'Super Admin'">
                        <td v-if="usr.client_name !== null">{{ usr.client_name }}</td>
                        <td v-else>-</td>
                      </template>
                      <td v-if="usr.group_name !== null">
                        {{ usr.group_name }}
                      </td>
                      <td v-else>-</td>
                      <td v-if="usr.subgroup_name !== null">
                        {{ usr.subgroup_name }}
                      </td>
                      <td v-else>-</td>
                      <td class="c">
                        <router-link
                          style="text-decoration: none"
                          :to="{
                            name: 'UpdateUser',
                            params: {
                              id: usr.id,
                              client_id: usr.client_id,
                              username: usr.username,
                              email: usr.email,
                              group: usr.group_name,
                              subgroup: usr.subgroup_name,
                              date_format_type_master_id:usr.date_format_type_master_id
                            }
                          }"
                        >
                          <i class="fa fa-pencil gr" aria-hidden="true"></i>
                        </router-link>
                        <!-- <a href="add_user.html">
                            <i class="fa fa-pencil gr" aria-hidden="true"></i>
                        </a>-->
                      </td>
                      <td class="c">
                        <a v-on:click="deleteUsr(usr.id, index)" :style="{ cursor: 'pointer' }">
                          <i class="fa fa-trash rd" aria-hidden="true"></i>
                        </a>
                      </td>
                    </tr>
                    <!------------------------>
                  </tbody>
                </table>
              </div>
            </div>

            <div v-if="loggedInUserGroupId === 'Super Admin'"  id="tables" class="tab-pane fade">
              <div class="table-responsive">
                <table id="myTable1" class="table table-hover myTable1">
                  <thead>
                    <tr>
                      <th>Table Name</th>
                      <!-- <th class="c"></th> -->
                      <!-- <th class="c"></th> -->
                    </tr>
                    <!------------------------>

                    <tr v-for="tbl in tables" :key="tbl.id">
                      <td v-if="tbl.table_name !== null">
                        {{ tbl.table_name }}
                      </td>
                      <td v-else>-</td>
                      <td class="c">
                        <a @click="$router.push('/1')">
                          <i class="fa fa-pencil gr" aria-hidden="true"></i>
                        </a>
                      </td>
                      <!-- <td class="c">
                          <a
                            v-on:click="deleteTbl(tbl.id)"
                            :style="{ cursor: 'pointer' }"
                          >
                            <i class="fa fa-trash rd" aria-hidden="true"></i>
                          </a>
                      </td>-->
                    </tr>
                    <!------------------------>

                    <!-- <tr>
                        <td>
                          <router-link
                            :to="{
                              name: 'MetaTable',
                              params: { master_table: 'benchmark' },
                            }"
                          >
                            Benchmark Master
                          </router-link>
                        </td>
                        <td class="c">
                          <router-link :to="{ name: 'Benchmark' }">
                            <i class="fa fa-pencil gr" aria-hidden="true"></i>
                          </router-link>
                        </td>
                    </tr>-->
                    <!-- <tr>
                        <td>
                          <router-link
                            :to="{
                              name: 'MetaTable',
                              params: { master_table: 'port' },
                            }"
                          >
                            Port Master
                          </router-link>
                        </td>
                        <td class="c">
                          <router-link :to="{ name: 'Port' }">
                            <i class="fa fa-pencil gr" aria-hidden="true"></i>
                          </router-link>
                        </td>
                    </tr>-->
                    <tr>
                      <td>
                        <router-link
                          :to="{
                            name: 'Benchmark'
                          }"
                          >Benchmark Master</router-link
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <router-link
                          :to="{
                            name: 'DayCount'
                          }"
                          >Day Count Master</router-link
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <router-link
                          :to="{
                            name: 'City'
                          }"
                          >City Master</router-link
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <router-link
                          :to="{
                            name: 'Country'
                          }"
                          >Country Master</router-link
                        >
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <router-link
                          :to="{
                            name: 'Expense'
                          }"
                          >Expense Master</router-link
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <router-link
                          :to="{
                            name: 'Covenants'
                          }"
                          >Covenant Master</router-link
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <router-link
                          :to="{
                            name: 'Document'
                          }"
                          >Document Master</router-link
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <router-link
                          :to="{
                            name: 'LoanExpenseMaster'
                          }"
                          >Loan Expense Master</router-link
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <router-link
                          :to="{
                            name: 'LoanTypeMaster'
                          }"
                          >Loan Type Master</router-link
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <router-link
                          :to="{
                            name: 'State'
                          }"
                          >State Master</router-link
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <router-link
                          :to="{
                            name: 'DocumentTypeMaster'
                          }"
                          >Document Type Master</router-link
                        >
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <router-link
                          :to="{
                            name: 'BorrowerCovenant'
                          }"
                          >Borrower Covenant Master</router-link
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <router-link
                          :to="{
                            name: 'Currency'
                          }"
                          >Currency Master</router-link
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <router-link
                          :to="{
                            name: 'InterestFrequency'
                          }"
                          >Interest Frequency Master</router-link
                        >
                      </td>
                    </tr>
                    <!-- <tr>
                        <td>
                          <router-link
                            :to="{
                              name: 'PaymentType_Table',
                            }"
                          >
                            Payment Type Master
                          </router-link>
                        </td>
                        <td class="c">
                          <router-link :to="{ name: 'PaymentType' }">
                            <i class="fa fa-pencil gr" aria-hidden="true"></i>
                          </router-link>
                        </td>
                    </tr>-->
                    <tr>
                      <td>
                        <router-link
                          :to="{
                            name: 'UnitType'
                          }"
                          >Unit Type Master</router-link
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <router-link
                          :to="{
                            name: 'CollateralQuality'
                          }"
                          >Collateral Master</router-link
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <router-link
                          :to="{
                            name: 'ContactCode'
                          }"
                          >Contact Code Master</router-link
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <router-link
                          :to="{
                            name: 'BusinessType'
                          }"
                          >Business Type Master</router-link
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <router-link
                          :to="{
                            name: 'Port'
                          }"
                          >Port Master</router-link
                        >
                      </td>
                    </tr>
                    <!------------------------>
                  </thead>
                </table>
              </div>
            </div>

            <div v-if="loggedInUserGroupId === 'Admin'" id="audit" class="tab-pane fade">
              <div class="table-responsive">
                <data-table />
                <!-- <table
                  class="
                    table table-head-fixed
                    text-nowrap
                    table-hover
                    myTable2
                  "
                  id="myTable2"
                >
                  <thead>
                    <tr>
                      <th>Timestamp</th>
                      <th>Username</th>
                      <th>Group Name</th>
                      <th>Action</th>
                      <th>Module Name</th>
                      <th>Tab Name</th>
                      <th>Field Name</th>
                      <th>Old Entry</th>
                      <th>New Entry</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr id="tdrow" v-for="rel in releases" :key="rel.id">
                      <template>
                        <td class="ln">
                          <router-link
                            active-link="active"
                            :to="{
                              name: 'ReleaseOverview',
                              params: {
                                id: rel.request_id,
                              },
                            }"
                            >{{ rel.request_id }}</router-link
                          >
                        </td>
                        <td>{{ rel.release_date }}</td>
                        <td>{{ rel.borrower_name }}</td>
                        <td>{{ rel.offtaker_name }}</td>
                        <td>{{ rel.total_bags }}</td>
                        <td
                          v-if="
                            rel.total_released_quantity !== '-' &&
                            rel.total_released_quantity !== null
                          "
                        >
                          {{ rel.total_released_quantity }}
                          {{ "MT" }}
                        </td>
                        <td v-else>-</td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </template>
                    </tr>
                  </tbody>
                </table> -->
              </div>
            </div>
            <!-- </div> -->
          </div>
        </div>
        <!-- all other page content start -->

        <!-- <div id="footer">
          <div class="container-fluid">
            <div class="pull-right hidden-xs">
              <b>Version</b> 2.4.0
            </div>Copyright 2021
          </div>
        </div>-->
      </div>
    </div>
    <!-------------Main container start--------------->
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { Utils } from "../../utils/CommonMethods.js";
import DataTable from "@/components/core/DataTable.vue";

export default {
  data() {
    return {
      //variables for Add User information
      GroupList: [],
      UserList: [],
      tables: [],
      selectedGroup: [],
      ClientList: [],
      storedMomentDateFormat: null,
    };
  },
  components: {
    DataTable
  },
  methods: {
    //this method will submit the facility form in backend.
    onGroupChange(name) {
      this.selectedGroup = this.UserList.filter(usr => name === usr.group_name);
    },

    deleteUsr(id, index) {
      var path = "adduser/" + this.$store.state.client_id;
      this.$confirm({
        auth: false,
        message: "Are you sure you want to delete?",
        button: {
          no: "No",
          yes: "Yes"
        },

        callback: confirm => {
          if (confirm) {
            axios
              .delete(path, {
                data: {
                  id: id
                }
              })
              .then(res => {
                if (res.data.status == "success") {
                  this.UserList.splice(index, 1);
                  Utils.make_alert("success", "User deleted successfully");
                  console.log("User deleted successfully");
                } else {
                  console.log("Something went wrong from server!");
                }
              })
              .catch(error => {
                console.error("error: " + error);
                console.log("Something went wrong!");
              });
          }
        }
      });
    }, //deleteUsr close

    deleteClient(id, index) {
      var path = "add_client/" + this.$store.state.client_id;
      this.$confirm({
        auth: false,
        message: "Are you sure you want to delete?",
        button: {
          no: "No",
          yes: "Yes"
        },

        callback: confirm => {
          if (confirm) {
            axios
              .delete(path, {
                data: {
                  id: id
                }
              })
              .then(res => {
                if (res.data.status == "success") {
                  this.ClientList.splice(index, 1);
                  Utils.make_alert("success", "Client deleted successfully");
                  console.log("Client deleted successfully");
                } else {
                  console.log("Something went wrong from server!");
                }
              })
              .catch(error => {
                console.error("error: " + error);
                console.log("Something went wrong!");
              });
          }
        }
      });
    }, //deleteClient close

    deleteGroup(id, index) {
      var path = "addgroup";
      this.$confirm({
        auth: false,
        message: "Are you sure you want to delete?",
        button: {
          no: "No",
          yes: "Yes"
        },

        callback: confirm => {
          if (confirm) {
            axios
              .delete(path, {
                data: {
                  id: id
                }
              })
              .then(res => {
                if (res.data.status == "success") {
                  this.GroupList.splice(index, 1);
                  console.log("Delete is successful.");
                } else {
                  console.log("Something went wrong from server Not able to delete!");
                }
              })
              .catch(error => {
                console.error("error: " + error);
                console.log("Something went wrong!");
              });
          }
        }
      });
    }, //getFund close

    getUserView() {
      var path = "groupname";
      axios
        .get(path)
        .then(res => {
          this.UserName = res.data.UserName;
          this.UserNameList = res.data.usernames;
        })
        .catch(error => {
          console.error(error);
        });
    }, //getUserView() close

    getPermissionData() {
      //this function is called on page load and created events. it will show details on fund with fundid equal to this.id
      const path = "permission";
      axios
        .get(path)
        .then(res => {
          console.log("PERMISSIONS", res.data);
          this.GroupList = res.data.permission.GroupList;
          this.UserList = res.data.permission.UserList;
          console.log("UserList",this.UserList)
          this.tables = res.data.permission.tables;
          if (res.data.status == "success") {
            console.log("get fund details is successful.");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch(error => {
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    }, //getPermissionData close

    getClientMaster() {
      //this function is called to get list of banks
      const path = "clientMaster";
      axios
        .get(path)
        .then((res) => {
          console.log(res)
          console.log("ClientMaster >>",res.data.ClientMaster)
          this.ClientList = res.data.clientDetails;
          this.ClientList.forEach(item => {
            if (item.incorporation_date != '-' && item.incorporation_date != null) {
              item.incorporation_date = moment(item.incorporation_date, "MM-DD-YYYY").format(this.storedMomentDateFormat);
            } else {
              item.incorporation_date = '-';
            }
            if (item.registration_date != '-' && item.registration_date != null) {
              item.registration_date = moment(item.registration_date, "MM-DD-YYYY").format(this.storedMomentDateFormat);
            } else {
              item.registration_date = '-';
            }
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },

  },
  computed: {
    loggedInUserGroupId() {
      var loggedInUserGroupName = localStorage.getItem("group_name");
      return loggedInUserGroupName;
    },
    loggedInClientId() {
      var clientId = localStorage.getItem("client_id");
      console.log(clientId);
      return clientId;
    }
  },
  mounted() {
    this.storedMomentDateFormat = localStorage.getItem("moment_date_format");
    //keep current tab active
    $('a[data-toggle="tab"]').on("shown.bs.tab", function(e) {
      localStorage.setItem("activeTab", $(e.target).attr("href"));
    });
    var activeTab = localStorage.getItem("activeTab");
    if (activeTab) {
      $('.nav-tabs a[href="' + activeTab + '"]').tab("show");
    }
  },
  created() {
    if(this.loggedInUserGroupId === 'Super Admin'){
      this.getClientMaster();
    }
    // getUsers() {
    const path = "adduser/" + this.$store.state.client_id;
    axios
      .get(path)
      .then(res => {
        console.log("USERSS>>", res.data.addusers);
        this.UserList = res.data.addusers;
        this.selectedGroup = this.UserList;
        console.log("selectedGroup",this.selectedGroup)
      })
      .catch(error => {
        console.error(error);
      });

    const path2 = "addgroup";
    axios
      .get(path2)
      .then(res => {
        console.log("GROUPSS>>", res.data);
        let GroupName = res.data.addgroups;
        this.GroupList = GroupName.filter(group => group.group_name != "Super Admin");
      })
      .catch(error => {
        console.error(error);
      });
    // }, //getGroupNames() close
  }
};
</script>

<style></style>
