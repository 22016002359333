<template>
  <!-- Navbar -->
  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button">
          <i class="icofont-navigation-menu"></i>
        </a>
      </li>
    </ul>

    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">
      <!-- Messages Dropdown Menu -->

      <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="#">
          <i class="icofont-notification"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <span class="dropdown-header">15 Notifications</span>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item"> Option 1 </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item"> Option 2 </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item"> Option 3 </a>
        </div>
      </li>

      <li class="nav-item">
            <router-link
              to="/logout"
              class="nav-link"
              role="button"><i class="icofont-power"></i>
            </router-link>


        <!-- <a class="nav-link" href="/logout" role="button"
          ><i class="icofont-power"></i
        ></a> -->
      </li>
    </ul>
  </nav>
</template>

<script>
import axios from "axios";

export default {
  name: 'NavBar',
  data() {
    return {
      notifCount: 0,
      noti: [],
    };
  },
  created() {
    axios
      .get("routes/stl-notification/self-stl-notifications/")
      .then((response) => {
        console.log(response.data);
      })
      .catch((err) => console.log(err));
  },
};
</script>

<style>
</style>