<template>
  <div>
    <div id="page-container">
      <!-------------Main container start--------------->

      <div id="content-wrap">
        <!-- all other page content start -->
        <admin-nav-bar></admin-nav-bar>

        <div
          class="container-fluid"
          id="headerbx"
          style="background-image: url(asset/images/tp_bg.png)"
        >
          <div class="container-fluid filtersearch">
            <div class="row">
              <div class="col-md-12">
                <div class="headingbg">
                  <h5>
                    <img
                      src="asset/images/summary.png"
                      class="img-responsive heading_image"
                      align="left"
                    />
                    Add Group
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid" id="contentmaindiv">
          <div class="whitepatch dualbox">
            <div class="row">
              <div class="col-md-12">
                <div class="form-inline">
                  <div class="form-group">
                    <label for="text" class="required-field">Add Group Name:</label>
                    <input
                      type="text"
                      class="form-control"
                      id="text"
                      placeholder="Enter Group Name"
                      v-model="GroupName"
                    />
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="row">
              <div class="col-sm-12">
                <select
                  multiple="multiple"
                  name="duallistbox_permissions[]"
                  id="test"
                  v-model="selectGroupName"
                >
                  <option :value="null">--- Select ---</option>
                  <option v-for="grp_name in GroupNameList" :key="grp_name.id">
                    {{ grp_name.group_name }}
                  </option>
                </select>
              </div>
            </div> -->

            <div class="row">
              <div class="col-md-12 text-right mb-3">
                <button
                  style="margin-right:6px"
                  type="button"
                  class="btn btn-info"
                  v-on:click="onSubmit()"
                  :disabled="isDisabledOnSubmit"
                >
                  Submit
                </button>
                <button
                  type="button"
                  class="btn btn-default"
                  @click="$router.go(-1)"
                  style="margin-right:6px cursor: pointer"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- all other page content start -->

      <div id="footer">
        <div class="container-fluid">
          <div class="pull-right hidden-xs"><b>Version</b> 2.4.0</div>
          Copyright 2021
          <a href="https://www.lundyinvestors.com/" target="_blank"
            >LUNDY INVESTORS</a
          >
        </div>
      </div>
    </div>
    <!-------------Main container start--------------->
  </div>
  <!-- all other page content start -->
</template>

<script>
import axios from "axios";
import { Utils } from "../../utils/CommonMethods.js";

export default {
  data() {
    return {
      //variables for Add User information
      GroupName: null,
      GroupNameList: [],
      selectGroupName: []
    };
  },
  computed: {
    //this is to computed disabled property of main Submit button to save information in database.
    isDisabledOnSubmit: function() {
      if (this.GroupName === null || this.GroupName === "") {
        //here we will check for all sections
        return true;
      }
    } //isDisabledOnSubmit
  }, //main computed close

  methods: {
    //this method will submit the facility form in backend.
    onSubmit() {
      //initialize the form data
      let formData = new FormData();
      //append company information to formdata
      formData.append("GroupName", this.GroupName);
      // formData.append("selectGroupName", this.selectGroupName);
      //append covenant information to formdata
      console.log(this.GroupName);

      const path = "/addgroup";

      axios
        .post(path, formData, {
          headers: {
            enctype: "multipart/form-data"
          }
        })
        .then(response => {
          console.log("status: " + response.data.status);
          if (response.data.status == "success") {
            Utils.make_alert("success", "Group added successfully!");
            console.log("SUCCESS!!");
            this.$router.push("/permission");
          } else {
            Utils.make_alert("success", "FAILURE!!! Something went wrong from server!");
            console.log("Something went wrong from server!");
            console.log("FAILURE!!");
          }
        })
        .catch(function() {
          console.log("FAILURE!!");
        });
    }, //onSubmit() close

    //this function is called to get list of documents master
    getGroupNames() {
      const path = "groupname";
      axios
        .get(path)
        .then(res => {
          let GroupName = res.data.groupnames;
          this.GroupNameList = GroupName.filter(group => group.group_name != "Super Admin");
        })
        .catch(error => {
          console.error(error);
        });
    } //getGroupNames() close
  },
  created() {
    this.getGroupNames();
  },
  mounted() {
    let recaptchaScript = document.createElement("link");
    recaptchaScript.setAttribute("href", "asset/dualbxstyle.css");
    document.head.appendChild(recaptchaScript);
    recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute("src", "asset/dualbxscript.js");
    document.body.appendChild(recaptchaScript);
  }
};
</script>

<style></style>
