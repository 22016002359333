<template>
  <div>
    <div class="se-pre-con"></div>
    <div id="page-container">
      <!-------------Main container start--------------->

      <div id="content-wrap">
        <!-- all other page content start -->

        <admin-nav-bar></admin-nav-bar>

        <div class="container-fluid" id="headerbx" style="background-image:url(assets/tp_bg.png);">
          <div class="container-fluid filtersearch">
            <div class="row">
              <div class="col-md-12">
                <div class="headingbg">
                  <h5>
                    <img
                      src="assets/summary.png"
                      class="img-responsive heading_image"
                      align="left"
                    />
                    User View
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid" id="contentmaindiv">
          <div class="whitepatch dualbox">
            <div class="fr_b">
              <div class="row">
                <div class="col-md-2 col-xs-6">
                  <div class="form-group">
                    <label for="text">User Name</label>
                    <input
                      type="text"
                      class="form-control"
                      id="text"
                      placeholder="User Name"
                      v-model="UserName"
                      disabled
                    />
                  </div>
                </div>

                <div class="col-md-2 col-xs-6">
                  <div class="form-group">
                    <label for="email">Email Address</label>
                    <input
                      type="email"
                      class="form-control"
                      id="email"
                      placeholder="Email Address"
                      v-model="email"
                      name="email"
                      disabled
                    />
                    <small>
                      <span style="color: red; cursor: pointer" v-if="msg.email">{{
                        msg.email
                      }}</span>
                    </small>
                  </div>
                </div>

                <div class="col-md-2 col-xs-12">
                  <div class="form-group">
                    <label>Group Name</label>
                    <div class="line"></div>
                    <select
                      disabled
                      id="selectedGroupName"
                      class="form-control"
                      name="selectedGroupName"
                      v-model="selectedGroupName"
                    >
                      <option :value="null">--- Select ---</option>
                      <option v-for="grp_name in GroupName" :key="grp_name.id">{{
                        grp_name.group_name
                      }}</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-2">
                  <button type="submit" class="btn btn-info mt">Edit Info</button>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <select
                  multiple="multiple"
                  name="duallistbox_permissions[]"
                  id="test"
                  disabled
                  v-model="selectUserName"
                >
                  <option :value="null">--- Select ---</option>
                  <option v-for="usr_name in UserNameList" :key="usr_name.id">{{
                    usr_name.user_name
                  }}</option>
                  <!-- <option value="option1" selected="selected" disabled>Option 1</option>
                  <option value="option2" selected="selected" disabled>Option 2</option>
                  <option value="option3" selected="selected" disabled>Option 3</option>
                  <option value="option4" selected="selected" disabled>Option 4</option>
                  <option value="option5" selected="selected" disabled>Option 5</option>
                  <option value="option6" selected="selected" disabled>Option 6</option>
                  <option value="option7" selected="selected" disabled>Option 7</option>
                  <option value="option8" selected="selected" disabled>Option 8</option>
                  <option value="option9" selected="selected" disabled>Option 9</option>
                  <option value="option0" selected="selected" disabled>Option 10</option>-->
                </select>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <button
                  type="button"
                  class="btn btn-default back-btn"
                  @click="$router.go(-1)"
                  style="margin-right:6px cursor:pointer;"
                >
                  <i class="icofont-caret-left text-dark"></i>
                  <span>Back</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- all other page content start -->

        <div id="footer">
          <div class="container-fluid">
            <div class="pull-right hidden-xs">
              <b>Version</b> 2.4.0
            </div>Copyright 2021
            <a href="https://www.lundyinvestors.com/" target="_blank">LUNDY INVESTORS</a>
          </div>
        </div>
      </div>
      <!-------------Main container start--------------->
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      //variables for Add User information
      UserName: null,
      email: null,
      selectedGroupName: null,
      GroupName: [],
      UserNameList: [],
      selectUserName: [],
      msg: []
    };
  },
  watch: {
    email(value) {
      // binding this to the data value in the email input
      this.email = value;
      this.validateEmail(value);
    }
  },
  methods: {
    validateEmail(value) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        this.msg["email"] = "";
      } else {
        this.msg["email"] = "Invalid Email Address";
      }
    },
    //this function is called to get list of documents master
    getUserView() {
      const path = "/groupname";
      axios
        .get(path)
        .then(res => {
          this.UserName = res.data.UserName;
          this.UserNameList = res.data.usernames;
        })
        .catch(error => {
          console.error(error);
        });
    } //getUserView() close
  }
};
</script>

<style></style>
