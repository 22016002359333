var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{attrs:{"id":"page-container"}},[_c('div',{attrs:{"id":"content-wrap"}},[_c('admin-nav-bar'),_vm._m(0),_c('div',{staticClass:"container-fluid",attrs:{"id":"contentmaindiv"}},[_c('div',{staticClass:"whitepatch dualbox"},[_c('div',{staticClass:"fr_b"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-2 col-xs-6"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"required-field",attrs:{"for":"text"}},[_vm._v("User Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.UserName),expression:"UserName"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.isSubmitted && _vm.$v.UserName.$error
                    },attrs:{"type":"text","id":"UserName","name":"UserName"},domProps:{"value":(_vm.UserName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.UserName=$event.target.value}}}),(_vm.isSubmitted && _vm.$v.UserName.$error)?_c('div',{staticClass:"invalid-feedback",staticStyle:{"color":"red"}},[(!_vm.$v.UserName.required)?_c('span',[_vm._v("User Name is required")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-2 col-xs-6"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"required-field",attrs:{"for":"email"}},[_vm._v("Email Address")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control",class:{ 'is-invalid': _vm.isSubmitted && _vm.$v.email.$error },attrs:{"type":"email","id":"email","name":"email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_c('small',[(_vm.msg.email)?_c('span',{staticStyle:{"color":"red","cursor":"pointer"}},[_vm._v(_vm._s(_vm.msg.email))]):_vm._e()]),(_vm.isSubmitted && _vm.$v.email.$error)?_c('div',{staticClass:"invalid-feedback",staticStyle:{"color":"red"}},[(!_vm.$v.email.required)?_c('span',[_vm._v("Email Address is required")]):_vm._e()]):_vm._e()])]),(_vm.loggedInUserGroupId === 'Super Admin')?_c('div',{staticClass:"col-md-2 col-xs-6"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"required-field"},[_vm._v("Client Name")]),_c('div',{staticClass:"line"}),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedClientID),expression:"selectedClientID"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.isSubmitted && _vm.$v.selectedClientID.$error
                    },attrs:{"id":"selectedClientID","name":"selectedClientID"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedClientID=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v("--- Select ---")]),_vm._l((_vm.clientDetails),function(cl){return _c('option',{key:cl.client_id,domProps:{"value":cl.client_id}},[_vm._v(" "+_vm._s(cl.client_name)+" ")])})],2),(_vm.isSubmitted && _vm.$v.selectedClientID.$error)?_c('div',{staticClass:"invalid-feedback",staticStyle:{"color":"red"}},[(!_vm.$v.selectedClientID.required)?_c('span',[_vm._v("Client Name is required")]):_vm._e()]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"col-md-2 col-xs-6"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"required-field"},[_vm._v("Group Name")]),_c('div',{staticClass:"line"}),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedGroupName),expression:"selectedGroupName"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.isSubmitted && _vm.$v.selectedGroupName.$error
                    },attrs:{"id":"selectedGroupName","name":"selectedGroupName"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedGroupName=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.getSubGroupName]}},[_c('option',{domProps:{"value":null}},[_vm._v("--- Select ---")]),_vm._l((_vm.GroupName),function(grp_name){return _c('option',{key:grp_name.id,domProps:{"value":grp_name.group_name}},[_vm._v(" "+_vm._s(grp_name.group_name)+" ")])})],2),(_vm.isSubmitted && _vm.$v.selectedGroupName.$error)?_c('div',{staticClass:"invalid-feedback",staticStyle:{"color":"red"}},[(!_vm.$v.selectedGroupName.required)?_c('span',[_vm._v("Group Name is required")]):_vm._e()]):_vm._e()])]),(
                  _vm.selectedGroupName == 'Investor' ||
                    _vm.selectedGroupName == 'Borrower' ||
                    _vm.selectedGroupName == 'Collateral Manager' ||
                    _vm.selectedGroupName == 'Offtaker'
                )?_c('div',{staticClass:"col-md-2 col-xs-6"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"required-field"},[_vm._v("SubGroup Names")]),_c('div',{staticClass:"line"}),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedSubGroupName),expression:"selectedSubGroupName"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.isSubmitted && _vm.$v.selectedSubGroupName.$error
                    },attrs:{"id":"selectedSubGroupName","name":"selectedSubGroupName"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedSubGroupName=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{domProps:{"value":null}},[_vm._v("--- New SubGroup ---")]),_vm._l((_vm.SubGroupName),function(grp_name){return _c('option',{key:grp_name.sub_group_id,domProps:{"value":grp_name.sub_group_id}},[_vm._v(" "+_vm._s(grp_name.subgroupname)+" ")])})],2),(_vm.isSubmitted && _vm.$v.selectedSubGroupName.$error)?_c('div',{staticClass:"invalid-feedback",staticStyle:{"color":"red"}},[(!_vm.$v.selectedSubGroupName.required)?_c('span',[_vm._v("SubGroup Name is required")]):_vm._e()]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"col-md-2 col-xs-6"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"required-field"},[_vm._v("Date Format")]),_c('div',{staticClass:"line"}),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedDateFormat),expression:"selectedDateFormat"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.isSubmitted && _vm.$v.selectedDateFormat.$error
                    },attrs:{"id":"selectedDateFormat","name":"selectedDateFormat"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedDateFormat=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v("--- Select ---")]),_vm._l((_vm.DateFormat),function(df){return _c('option',{key:df.id,domProps:{"value":df.id}},[_vm._v(" "+_vm._s(df.name)+" ")])})],2),(_vm.isSubmitted && _vm.$v.selectedDateFormat.$error)?_c('div',{staticClass:"invalid-feedback",staticStyle:{"color":"red"}},[(!_vm.$v.selectedDateFormat.required)?_c('span',[_vm._v("Date Format is required")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-md-2 col-xs-6"},[(_vm.error)?_c('error',{attrs:{"error":_vm.error}}):_vm._e(),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"required-field",attrs:{"for":"text"}},[_vm._v("Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.isSubmitted && _vm.$v.password.$error
                    },attrs:{"type":"password","id":"password","name":"password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),(_vm.isSubmitted && _vm.$v.password.$error)?_c('div',{staticClass:"invalid-feedback",staticStyle:{"color":"red"}},[(!_vm.$v.password.required)?_c('span',[_vm._v("Password field is required")]):_vm._e(),(_vm.$v.password.required && !_vm.$v.password.minLength)?_c('span',[_vm._v("Password should be at least 6 characters long")]):_vm._e(),(_vm.$v.password.required && !_vm.$v.password.maxLength)?_c('span',[_vm._v("Password should not be more than 20 characters long")]):_vm._e(),(
                        _vm.$v.password.required &&
                          _vm.$v.password.minLength &&
                          _vm.$v.password.maxLength &&
                          !_vm.$v.password.valid
                      )?_c('span',[_vm._v("Password must have atleast One Uppercase, One Lowercase, One Number and One Special Character")]):_vm._e()]):_vm._e()])],1),_c('div',{staticClass:"col-md-2 col-xs-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"lb required-field",attrs:{"for":"text"}},[_vm._v("Confirm Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmpassword),expression:"confirmpassword"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.isSubmitted && _vm.$v.confirmpassword.$error
                    },attrs:{"type":"password","id":"confirmpassword","name":"confirmpassword"},domProps:{"value":(_vm.confirmpassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.confirmpassword=$event.target.value}}}),(_vm.isSubmitted && _vm.$v.confirmpassword.$error)?_c('div',{staticClass:"invalid-feedback",staticStyle:{"color":"red"}},[(!_vm.$v.confirmpassword.required)?_c('span',[_vm._v("Confirm Password field is required")]):_vm._e(),(_vm.$v.confirmpassword.required && !_vm.$v.confirmpassword.sameAsPassword)?_c('span',[_vm._v("Passwords should be matched")]):_vm._e()]):_vm._e()])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 text-right mb-3"},[_c('button',{staticClass:"btn btn-info",staticStyle:{"margin-right":"6px"},attrs:{"type":"button"},on:{"click":function($event){return _vm.onSubmit()}}},[_vm._v(" Submit ")]),_c('button',{staticClass:"btn btn-default",staticStyle:{"margin-right":"6px cursor: pointer"},attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" Cancel ")])])])])]),_vm._m(1)],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid",staticStyle:{"background-image":"url(asset/images/tp_bg.png)"},attrs:{"id":"headerbx"}},[_c('div',{staticClass:"container-fluid filtersearch"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"headingbg"},[_c('h5',[_c('img',{staticClass:"img-responsive heading_image",attrs:{"src":"asset/images/summary.png","align":"left"}}),_vm._v("Add User ")])])])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"footer"}},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"pull-right hidden-xs"},[_c('b',[_vm._v("Version")]),_vm._v(" 2.4.0")]),_vm._v(" Copyright 2021 "),_c('a',{attrs:{"href":"https://www.lundyinvestors.com/","target":"_blank"}},[_vm._v("LUNDY INVESTORS")])])])}]

export { render, staticRenderFns }