<template>
  <div>
    <div id="page-container">
      <!-------------Main container start--------------->

      <div id="content-wrap">
        <!-- all other page content start -->
        <admin-nav-bar></admin-nav-bar>

        <div
          class="container-fluid"
          id="headerbx"
          style="background-image:url(asset/images/tp_bg.png);"
        >
          <div class="container-fluid filtersearch">
            <div class="row">
              <div class="col-md-12">
                <div class="headingbg">
                  <h5>
                    <img
                      src="asset/images/summary.png"
                      class="img-responsive heading_image"
                      align="left"
                    />
                    Currency Master Table
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid" id="contentmaindiv">
          <div class="whitepatch dualbox">
            <div class="row">
              <div class="col-md-12">
                <div class="addusr">
                  <a type="button" class="btn btn-succes back-btn" @click="$router.go(-1)">
                    <i class="icofont-caret-left text-dark"></i>
                    <span>Back</span>
                  </a>
                </div>
              </div>
            </div>
            <div class="fr_b">
              <div class="row">
                <div class="col-md-2 col-xs-6">
                  <div class="form-group">
                    <label for="text" class="required-field">Currency Name</label>
                    <input
                      v-model="currencyName"
                      type="text"
                      class="form-control"
                      id="currencyName"
                      name="currencyName"
                      :class="{ 'is-invalid': isSubmitted && $v.currencyName.$error }"
                      placeholder="Type..."
                    />
                    <div
                      v-if="isSubmitted && $v.currencyName.$error"
                      class="invalid-feedback"
                      style="color: red;"
                    >
                      <span v-if="!$v.currencyName.required">Currency Name is required</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-xs-6">
                  <div class="form-group">
                    <label for="text" class="required-field">Currency Short Name</label>
                    <input
                      v-model="currencyShortName"
                      type="text"
                      class="form-control"
                      id="currencyShortName"
                      name="currencyShortName"
                      :class="{ 'is-invalid': isSubmitted && $v.currencyShortName.$error }"
                      placeholder="Type..."
                    />
                    <div
                      v-if="isSubmitted && $v.currencyShortName.$error"
                      class="invalid-feedback"
                      style="color: red;"
                    >
                      <span v-if="!$v.currencyShortName.required"
                        >Currency Short Name is required</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-xs-6">
                  <div class="form-group">
                    <label for="text">Currency Symbol</label>
                    <input
                      v-model="currencySymb"
                      type="text"
                      class="form-control"
                      id="cursymb"
                      placeholder="Type..."
                    />
                  </div>
                </div>

                <!-- <div class="col-md-2 col-xs-6">
                  <div class="form-group">
                    <label>Created by</label>
                    <div class="line"></div>
                    {{ currentUser }}
                  </div>
                </div>-->
              </div>
            </div>

            <div v-if="update == true" class="row">
              <div class="col-md-12">
                <button
                  style="margin-right:6px"
                  type="button"
                  class="btn btn-info"
                  v-on:click="onUpdate()"
                >
                  Update
                </button>
                <button
                  type="button"
                  class="btn btn-default"
                  @click="onCancel()"
                  style="margin-right:6px cursor: pointer"
                >
                  Cancel
                </button>
              </div>
            </div>
            <div v-else class="row">
              <div class="col-md-12">
                <button
                  style="margin-right:6px"
                  type="button"
                  class="btn btn-info"
                  v-on:click="onSubmit()"
                >
                  Submit
                </button>
                <!-- <button
                  type="button"
                  class="btn btn-default"
                  @click="$router.go(-1)"
                  style="margin-right:6px cursor: pointer"
                >
                  Cancel
                </button>-->
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid" id="contentmaindiv">
          <div class="whitepatch">
            <div class="table-responsive">
              <table id="myTable1" class="table table-hover myTable1">
                <thead>
                  <tr>
                    <!-- <th class="c">Sr.no.</th> -->
                    <th>Currency Name</th>
                    <th>Currency Short Name</th>
                    <th>Currency Symbol</th>
                    <!-- <th>Created By</th>
                    <th>Updated By</th>-->
                    <th class="c"></th>
                    <th class="c"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(usr, index) in UserList" :key="usr.id">
                    <!-- <td class="c">{{ usr.id }}</td> -->
                    <td
                      v-if="
                        usr.currency_name !== null &&
                          usr.currency_name !== 'None' &&
                          usr.currency_name !== ''
                      "
                    >
                      {{ usr.currency_name }}
                    </td>
                    <td v-else>-</td>
                    <td
                      v-if="
                        usr.currency_short_name !== null &&
                          usr.currency_short_name !== 'None' &&
                          usr.currency_short_name !== ''
                      "
                    >
                      {{ usr.currency_short_name }}
                    </td>
                    <td v-else>-</td>
                    <td
                      v-if="
                        usr.currency_symbol !== null &&
                          usr.currency_symbol !== 'None' &&
                          usr.currency_symbol !== ''
                      "
                    >
                      {{ usr.currency_symbol }}
                    </td>
                    <td v-else>-</td>
                    <!-- <td>{{ usr.created_by }}</td>
                    <td>{{ usr.updated_by }}</td>-->
                    <td class="c">
                      <a v-on:click="getData(usr.id)" :style="{ cursor: 'pointer' }">
                        <i class="fa fa-pencil gr" aria-hidden="true"></i>
                      </a>
                    </td>
                    <td class="c">
                      <a v-on:click="onDelete(usr.id, index)" :style="{ cursor: 'pointer' }">
                        <i class="fa fa-trash rd" aria-hidden="true"></i>
                      </a>
                    </td>
                  </tr>
                  <!------------------------>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- all other page content start -->

        <div id="footer">
          <div class="container-fluid">
            <div class="pull-right hidden-xs"><b>Version</b> 2.4.0</div>
            Copyright 2021
            <a href="https://www.lundyinvestors.com/" target="_blank">LUNDY INVESTORS</a>
          </div>
        </div>
      </div>
      <!-------------Main container start--------------->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Utils } from "../../../utils/CommonMethods.js";
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      //variables for Add User information
      id: null,
      path: "admin-currency",
      UserList: [],
      update: false,
      currentUser: this.$store.getters.getuserFullName || [],
      currencyName: null,
      currencySymb: null,
      currencyShortName: null,
      createdBy: null,
      updatedBy: null,
      error: "",
      isSubmitted: false
    };
  },
  validations: {
    currencyName: {
      required
    },
    currencyShortName: {
      required
    }
  },
  computed: {
    // isDisabledOnSubmit: function() {
    //   if (
    //     this.currencyName === null ||
    //     this.currencySymb === null ||
    //     this.currencyShortName === null
    //   ) {
    //     //here we will check for all sections
    //     return true;
    //   }
    // } //isDisabledOnSubmit
  },
  methods: {
    onSubmit() {
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      var payload = {
        CurrencyName: this.currencyName,
        CurrencyShortName: this.currencyShortName,
        CurrencySymbol: this.currencySymb,
        createdBy: this.$store.getters.getuserId
      };

      axios
        .post(this.path, payload)
        .then(response => {
          console.log("status: " + response.data.status);
          if (response.data.status == "success") {
            Utils.make_alert("success", "Currency Master added successfully!");
            console.log("SUCCESS!!");
            setTimeout(() => this.$router.go(), 1500);
          } else {
            Utils.make_alert("success", "FAILURE!!! Something went wrong from server!");
            console.log("Something went wrong from server!");
            console.log("FAILURE!!");
          }
        })
        .catch(function() {
          console.log("FAILURE!!");
        });
    }, //onSubmit() close
    getData(id) {
      this.update = true;
      this.id = id;
      //this function is called on page load and created events. it will show details on fund with fundid equal to this.id
      axios
        .get(this.path + "?id=" + this.id)
        .then(res => {
          var data;
          data = res.data.currencies[0];
          this.currencyName = data.currency_name;
          this.currencySymb = data.currency_symbol;
          this.currencyShortName = data.currency_short_name;
          this.createdBy = data.created_by;
          this.updatedBy = data.updated_by;
          if (res.data.status == "success") {
            console.log("get fund details is successful.");
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch(error => {
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    }, //getFund close
    onUpdate() {
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      var payload = {
        id: this.id,
        CurrencyName: this.currencyName,
        CurrencyShortName: this.currencyShortName,
        CurrencySymbol: this.currencySymb,
        updatedBy: this.$store.getters.getuserId
      };
      this.$confirm({
        auth: false,
        message: "Are you sure you want to update?",
        button: {
          no: "No",
          yes: "Yes"
        },

        callback: confirm => {
          if (confirm) {
            axios
              .put(this.path, payload)
              .then(response => {
                console.log("status: " + response.data.status);
                if (response.data.status == "success") {
                  Utils.make_alert("success", "Currency Master updated successfully!");
                  (this.currencyName = null),
                    (this.currencySymb = null),
                    (this.currencyShortName = null),
                    console.log("SUCCESS!!");
                  this.update = false;
                  setTimeout(() => this.$router.go(), 1500);
                } else {
                  Utils.make_alert("success", "FAILURE!!! Something went wrong from server!");
                  console.log("Something went wrong from server!");
                  console.log("FAILURE!!");
                }
              })
              .catch(function() {
                console.log("FAILURE!!");
              });
          }
        }
      });
    }, //onSubmit() close
    //this method will submit the facility form in backend.
    getTableData() {
      //this function is called on page load and created events. it will show details on fund with fundid equal to this.id
      axios
        .get(this.path)
        .then(res => {
          this.UserList = res.data.currencies;
          console.log(this.UserList);
          if (res.data.status == "success") {
            console.log("get fund details is successful.");
            console.log(this.UserList);
          } else {
            console.log("Something went wrong from server!");
          }
        })
        .catch(error => {
          console.error("error: " + error);
          console.log("Something went wrong!");
        });
    }, //getFund close
    onDelete(id, index) {
      this.$confirm({
        auth: false,
        message: "Are you sure you want to delete?",
        button: {
          no: "No",
          yes: "Yes"
        },

        callback: confirm => {
          if (confirm) {
            axios
              .delete(this.path, {
                data: {
                  id: id
                }
              })
              .then(res => {
                if (res.data.status == "success") {
                  this.UserList.splice(index, 1);
                  Utils.make_alert("success", "Currency Deleted successfully.");
                  console.log("Currency Deleted successfully.");
                  setTimeout(() => this.$router.go(), 1500);
                } else {
                  console.log("Something went wrong from server!");
                }
              })
              .catch(error => {
                console.error("error: " + error);
                console.log("Something went wrong!");
              });
          }
        }
      });
    }, //getFund close
    onCancel() {
      this.update = false;
      this.currencyName = null;
      this.currencySymb = null;
      this.currencyShortName = null;
    }
  },
  created() {
    this.getTableData();
  }
};
</script>

<style>
.required-field::after {
  content: "*";
  color: red;
}
.back-btn span {
  text-transform: uppercase;
  font-size: 15px !important;
  color: #a6a6a6;
  position: relative;
  top: -3px;
  left: -8px;
}
.back-btn i {
  font-size: 24px;
  color: #343a40 !important;
  padding-right: 5px;
}
</style>
